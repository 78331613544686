import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import useSessionFile from '../../../hooks/useSessionFile';
import styles from './index.module.sass';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { parentRedirect, haveNoLoginRedirect } from '../../../hoc/redirectComp';
import { SettingOutlined } from '@ant-design/icons';
import FileList from '../FileList';
import ASTPagination from '../ASTPagination';

const SessionDetail = () => {
  const { id } = useParams();
  const {
    isFileLoading,
    data,
    handlePinnedFile,
    handleDeleteFile,
    handleUpdateFile,
    handleMarkAsViewed,
    handleResetViewers,
    meta,
    page,
    setPage,
  } = useSessionFile(id);

  const totalPages = Math.ceil(meta?.total / meta?.limit);

  const folder = data?.folder;
  const files = data?.files ?? [];

  return (
    <Spin
      spinning={isFileLoading}
      style={{
        width: '100%',
      }}
    >
      <div className={styles.container}>
        <section className={styles.section}>
          <div className={styles.section__flexContainer}>
            {/* <Link className={styles.button__link} to="/my-training-sessions">
              <ArrowLeftOutlined />
              <span className={styles.button__title}>
                {width <= MOBILE_DIMENSION ? 'Go Back' : 'Back to Training Sessions'}
              </span>
            </Link> */}
            <div className={styles.title}>{folder?.title ?? ''}</div>
            <button
              className={styles.grayBtn + ' ' + styles.reset}
              onClick={() => handleResetViewers(id)}
            >
              Reset All Completion Status
            </button>
          </div>
        </section>
        <div
          className={styles.section__flexContainer_training}
          style={{ justifyContent: 'space-between' }}
        >
          <div className={styles.subtitle}>Training Videos</div>
          <Link className={styles.button__reward} to="/my-training-sessions">
            <SettingOutlined />
            <span className={styles.button__title}>Manage Session</span>
          </Link>
        </div>
        <section className={styles.section}>
          <FileList
            files={files}
            handlePinnedFile={handlePinnedFile}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
            handleMarkAsViewed={handleMarkAsViewed}
          />

          <ASTPagination
            maxPage={totalPages}
            currentPage={page}
            setCurrentPage={setPage}
            limit={meta?.limit || 1}
          />
        </section>
      </div>
    </Spin>
  );
};

export default compose(withRouter, parentRedirect, haveNoLoginRedirect)(SessionDetail);
