import React, { useState } from 'react';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './index.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';

export function CreateSession({ handleSubmit, onClose, edit, folder = {}, type = 'session' }) {
  const [data, setData] = useState(folder);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClosePopupButtonClick = () => {
    onClose();
  };

  const submitForm = () => {
    const newData = {
      ...data,
    };

    if (type === 'session') {
      newData.subTitle = data.title.toLowerCase().replace(/\s+/g, '-');
    }

    handleSubmit(newData, {
      onSettled: () => handleClosePopupButtonClick(),
    });
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.create_form}>
        <ClosePopupButton handleClosePopupButtonClick={handleClosePopupButtonClick} />
        <h2 className={styles.create_form__title}>
          {edit ? 'Edit' : 'Create'} Training {type}
        </h2>
        <div className={styles.flex_container}>
          <form className={styles.form}>
            <label className={styles.form__label}>
              Title
              <input
                type="text"
                name="title"
                value={data.title}
                className={styles.form__input}
                onChange={handleChange}
              />
            </label>
            <p className={styles.form__importance}>This information can be changed later</p>
            <div className={styles.buttons}>
              <BorderedButton
                handleButtonClick={handleClosePopupButtonClick}
                width={'20rem'}
              ></BorderedButton>
              <RedButton
                handleButtonClick={submitForm}
                title={`${edit ? 'Update' : 'Create'} Session`}
                width={'20rem'}
              ></RedButton>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
