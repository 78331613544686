import React from 'react';
import { setSubcategory } from '../../../Redux/listViewSubcategoryReducer';
import { setCategory } from '../../../Redux/listViewCategoryReducer';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ListViewPage.module.sass';
import ArrowGrey from './arrow_grey.png';
import ArrowRed from './arrow_red.png';
import ChevronRed from './down_arrow_red.png';
import ChevronGrey from './up_arrow_grey.png';

const LeftSideBar = () => {
  const { data } = useSelector((state) => state.listViewReducer);
  const selectedCategory = useSelector((state) => state.listViewCategoryReducer);
  const selectedSubCategory = useSelector((state) => state.listViewSubcategoryReducer);
  const dispatch = useDispatch();
  const activeStyle = { color: '#86adc5' };

  return (
    <ul>
      {data.map((category, index) => {
        return (
          <div key={category.title} className={styles.menuRow}>
            {index === 4 && (
              <div
                className={styles.menuItem}
                style={{
                  fontSize: '18px',
                  marginTop: '10px',
                  color: '#164162',
                }}
              >
                All Programs
              </div>
            )}
            {index === 14 && (
              <div
                className={styles.menuItem}
                style={{
                  fontSize: '18px',
                  marginTop: '10px',
                  color: '#164162',
                }}
              >
                Other
              </div>
            )}
            <div
              onClick={() => {
                dispatch(setCategory(category?.actionType));
              }}
            >
              <div className={styles.menuItem}>
                <img
                  className={styles.icon}
                  src={category?.actionType === selectedCategory ? ArrowGrey : ArrowRed}
                />
                <span style={category?.actionType === selectedCategory ? activeStyle : {}}>
                  {category.title}
                </span>
              </div>
              <div className={styles.chevronColumn}>
                <img
                  className={styles.chevron}
                  src={category?.actionType === selectedCategory ? ChevronGrey : ChevronRed}
                />
              </div>
            </div>
            {category?.actionType === selectedCategory ? (
              <ul className={styles.subCategory}>
                {category.children.map((child) => (
                  <li
                    key={child.title}
                    style={child?.actionType === selectedSubCategory ? activeStyle : {}}
                    onClick={() => {
                      dispatch(setSubcategory(child?.actionType));
                    }}
                  >
                    {child.title}
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
        );
      })}
    </ul>
  );
};

export default LeftSideBar;
