import React from 'react';
import styles from './BorderedButton.module.sass';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../constants/const.js';

export function BorderedButton(props) {
  const { title = 'Cancel' } = props;
  const handleButtonClick = () => {
    if (typeof props.handleButtonClick === 'function') props.handleButtonClick();
  };
  const { width } = useWindowDimensions();

  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className={props.className + ' ' + styles.bordered_button}
    >
      {!!props.children && props.children}
      {width <= MOBILE_DIMENSION && props.width ? (
        <p style={{ width: props.width }}>{title}</p>
      ) : (
        <p>{title}</p>
      )}
    </button>
  );
}
