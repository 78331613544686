import React, { useState } from 'react';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopap.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import useMySessionFolder from '../../../hooks/useMySessionFolder';
import { Spin } from 'antd';
import ASTPagination from '../../MyTrainingSessions/ASTPagination';
import useSessionFile from '../../../hooks/useSessionFile';
import useMySessionFiles from '../../../hooks/useMySessionFiles';
import { CreateSession } from '../../MyTrainingSessions/Create';

export function ModalTrainingSession({ onClose, data }) {
  const { data: foldersData, foldersFetching, setPage, page, meta } = useMySessionFolder();
  const { handleAddFile } = useSessionFile();
  const [selectedSessions, setSelectedSessions] = useState([]);
  const { filesData, isFilesLoading } = useMySessionFiles();
  const [create, setCreate] = useState(false);

  const totalPages = Math.ceil(meta?.total / meta?.limit);

  const handleSelectedSession = (session) => {
    setSelectedSessions((prev) => {
      if (prev.some((t) => t.id === session.id)) {
        return prev.filter((item) => item.id !== session.id);
      } else {
        return [...prev, session];
      }
    });
  };

  const onSubmit = () => {
    const newData = {
      folderIds: selectedSessions,
      title: data?.text ?? data?.title,
      markAll: false,
      link: data.link,
    };

    handleAddFile(newData, {
      onSettled: () => onClose(),
    });
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton handleClosePopupButtonClick={onClose} />
        <h3 style={{ padding: '0 7rem' }} className={styles.invite_popup__title}>
          Assignment
        </h3>
        <div className={styles.container}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h5 style={{ fontWeight: 600, fontSize: '16px' }}>Training Session Videos</h5>
            {foldersData.length > 0 && (
              <button
                style={{
                  border: 'none',
                  outline: 'none',
                  background: 'none',
                  fontWeight: 600,
                  fontSize: '15px',
                  color: '#e1343f',
                  textDecoration: 'underline',
                }}
              >
                MARK ALL SESSIONS
              </button>
            )}
          </div>
          <Spin spinning={foldersFetching && isFilesLoading}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gap: '1rem',
                marginTop: '1rem',
              }}
            >
              {foldersData.map((item) => {
                const readOnly = filesData.some(
                  (file) => file.link === data.link && item.id === file.folderId,
                );
                const checked =
                  selectedSessions.some((session) => session.id === item.id) || readOnly;
                return (
                  <div
                    className={styles.form__label__checkbox}
                    key={item.id}
                    style={{
                      display: 'flex',
                    }}
                  >
                    <input
                      type="checkbox"
                      id={item.id}
                      checked={checked}
                      disabled={readOnly}
                      onChange={() => handleSelectedSession(item)}
                    />
                    <label htmlFor={item.id}>{item.title}</label>
                  </div>
                );
              })}
            </div>

            <ASTPagination
              maxPage={totalPages}
              currentPage={page}
              setCurrentPage={setPage}
              limit={meta?.limit || 1}
            />
          </Spin>
        </div>
        <div className={styles.container} style={{ paddingTop: '0px' }}>
          <div style={{ borderBottom: '0.1rem solid #bfcdd9', marginBottom: '2rem' }} />
          {/* <div
            className={styles.form__label__checkbox}
            style={{
              display: 'flex',
            }}
          >
            <button
              className={`${styles.button} ${styles.button__award}`}
              onClick={() => setCreate(true)}
            >
              <img className={styles.button__image} src={Plus} alt="" />
              Add Training Session
            </button>
          </div> */}
          <div className={styles.buttons}>
            <button
              style={{
                border: 'none',
                outline: 'none',
                background: 'none',
                fontWeight: 600,
                fontSize: '15px',
                color: '#78a3be',
                textDecoration: 'underline',
                flexShrink: 0,
              }}
            >
              Clear All
            </button>
            <BorderedButton handleButtonClick={onClose} width={'35rem'} />
            <RedButton
              width={'35rem'}
              handleButtonClick={() => onSubmit(selectedSessions, data)}
              title={'Save'}
            />
          </div>
        </div>
      </section>
      {create && <CreateSession onClose={() => setCreate(false)} />}
    </>
  );
}
