import React, { useState } from 'react';
import useFindSessionFolder from '../../../hooks/useFindSessionFolder';
import List from '../List';
import { Spin } from 'antd';
import ASTPagination from '../ASTPagination';
import { MIN_SEARCH_REQUEST_LENGTH, MOBILE_DIMENSION } from '../../../constants/const';
import styles from './index.module.sass';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { RedButton } from '../../commonComponents/RedButton/RedButton';

const MAX_SEARCH_REQUEST_LENGTH = 40;

const FindSession = () => {
  const { data, foldersFetching, meta, page, setPage, setSearchField, setKeyword, keyword } =
    useFindSessionFolder();
  const { width } = useWindowDimensions();
  const [input, setInput] = useState('');

  const handleSearchInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSearchSubmit = () => {
    setSearchField('title');
    setKeyword(input);
    setPage(1);
  };

  const totalPages = Math.ceil(meta?.total / meta?.limit);

  return (
    <Spin
      spinning={foldersFetching}
      style={{
        width: '100%',
      }}
    >
      <article className={styles.search}>
        <div className={styles.container}>
          <input
            type="text"
            className={styles.search__input}
            defaultValue={keyword}
            minLength={MIN_SEARCH_REQUEST_LENGTH}
            maxLength={MAX_SEARCH_REQUEST_LENGTH}
            aria-required="true"
            autoFocus={width >= MOBILE_DIMENSION ? true : false}
            autoComplete="on"
            onChange={handleSearchInputChange}
          />
          <p className={styles.search__info}>
            Enter any part of the training session&apos;s title in the search bar.
          </p>
        </div>
        <RedButton title={'Search'} handleButtonClick={handleSearchSubmit}>
          {width >= MOBILE_DIMENSION && (
            <img
              src="/img/Shape 2.webp"
              className={styles.button_img}
              style={{ paddingRight: '1rem' }}
              alt="Magnifying glass icon"
            />
          )}
        </RedButton>
      </article>
      <List data={data} view="find-session" />
      <div
        style={{
          marginBottom: '10px',
        }}
      />
      <ASTPagination
        maxPage={totalPages}
        currentPage={page}
        setCurrentPage={setPage}
        limit={meta?.limit || 1}
      />
    </Spin>
  );
};

export default FindSession;
