import { SubfolderPageUrl } from '../constants/const';

const ADD_FAVORITE_SUBCATEGORY = 'ADD_FAVORITE_SUBCATEGORY';
const SET_FAVORITE_SUBCATEGORY = 'SET_FAVORITE_SUBCATEGORY';
const ADD_HOMEWORK = 'ADD_HOMEWORK';
const DELETE_HOMEWORK = 'DELETE_HOMEWORK';
const SET_HOMEWORK = 'SET_HOMEWORK';

const ADD_HIDDEN = 'ADD_HIDDEN';
const DELETE_HIDDEN = 'DELETE_HIDDEN';
const SET_HIDDEN = 'SET_HIDDEN';

const SET_MY_TRAINING_SESSIONS_SUBCATEGORY = 'SET_MY_TRAINING_SESSIONS_SUBCATEGORY';

const initialState = {
  data: [
    {
      title: `My Favorites`,
      subTitle: '',
      actionType: 'SET_MY_FAVORITE_DATA',
      children: [
        {
          title: `My Favorites`,
          subTitle: '',
          actionType: 'SET_MY_FAVORITE_CATEGORY_DATA',
          children: [
            {
              title: `My Favorites`,
              subTitle: '',
              actionType: 'SET_MY_FAVORITE_SUBCATEGORY_DATA',
              children: [],
            },
          ],
        },
      ],
    },
    {
      title: `My Homework`,
      subTitle: '',
      actionType: 'SET_MY_HOMEWORK_DATA',
      children: [],
    },
    {
      title: 'Get Started Section',
      subTitle: '',
      actionType: 'SET_GET_STARTED_DATA',
      children: [
        {
          title: 'Ball Mastery',
          subTitle: '',
          actionType: 'SET_BALL_MASTERY_GS_DATA',
          children: [
            {
              title: 'Ball Mastery',
              subTitle: '',
              actionType: 'SET_BALL_MASTERY_GS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: 1,000 Touch Ball Mastery Section One',
                  subTitle: '1000 Touch P1 Part 1 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: 1,000 Touch Ball Mastery Section Two',
                  subTitle: '1000 Touch P1 Part 1 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: One Cone Ball Mastery Section One',
                  subTitle: '1 Cone Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Two Cone Ball Mastery Section One',
                  subTitle: 'Dual Cone Pro P1 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Wall Passing',
          subTitle: '',
          actionType: 'SET_WALL_PASSING_GS_DATA',
          children: [
            {
              title: 'Wall Passing',
              subTitle: '',
              actionType: 'SET_WALL_PASSING_GS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: Wall Passing Section One',
                  subTitle: 'First Touch Pro P1 01 Part One',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Wall Passing Section Two',
                  subTitle: 'First Touch Pro P1 02 Part Two',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Juggling',
          subTitle: '',
          actionType: 'SET_JUGGLING_GS_DATA',
          children: [
            {
              title: 'Juggling',
              subTitle: '',
              actionType: 'SET_JUGGLING_GS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: Thigh Juggling Absolute Beginners',
                  subTitle: '2 Footed Thigh Juggling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Kick and Catch Juggling',
                  subTitle: 'Catch & Hit Juggle 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Intermediate Juggling',
                  subTitle: 'Intermediate',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Advanced Juggling',
                  subTitle: 'Advanced',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Juggling Warm-up',
                  subTitle: '(Aerial) Warm-up Juggle',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Tight Space Dribbling',
          subTitle: '',
          actionType: 'SET_TIGHT_SPACE_DRIBBLING_GS_DATA',
          children: [
            {
              title: 'Tight Space Dribbling',
              subTitle: '',
              actionType: 'SET_TIGHT_SPACE_DRIBBLING_GS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: Figure 8 Dribbling',
                  subTitle: 'Figure 8 Close Control 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Freestyle Dribbling',
                  subTitle: 'Brief Freestyle 02 Free',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Three Cone Tight Dribbling',
                  subTitle: 'Fast Speed Tight Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: `My Training Sessions`,
      subTitle: '',
      actionType: 'SET_MY_SESSION_DATA',
      children: [
        {
          title: `My Training Sessions`,
          subTitle: '',
          actionType: 'SET_MY_SESSION_CATEGORY_DATA',
          children: [
            {
              title: `My Training Sessions`,
              subTitle: '',
              actionType: 'SET_MY_SESSION_SUBCATEGORY_DATA',
              children: [],
            },
          ],
        },
      ],
    },
    // {
    //   title: `All Programs`,
    //   subTitle: '',
    //   actionType: 'SET_ALL_PROGRAMS_DATA',
    //   children: [
    //     {
    //       title: 'Dynamic Warmups',
    //       subTitle: '',
    //       actionType: 'SET_DYNAMIC_WARMUPS_DATA',
    //       children: [{
    //         title: 'Dynamic Warmups',
    //         subTitle: '',
    //         actionType: 'SET_DYNAMIC_WARMUPS_CHILDREN_DATA',
    //         children: [
    //           {
    //             title: 'P1: Functional Movements, Dribbling & Juggling',
    //             subTitle: 'Dynamic Warmup Pro 01 Part',
    //             actionType: SubfolderPageUrl.videoSeries,
    //           },
    //           {
    //             title: 'P2: Functional Movements, Dribbling & Juggling',
    //             subTitle: 'Dynamic Warmup Pro 02 Part',
    //             actionType: SubfolderPageUrl.videoSeries,
    //           },
    //           {
    //             title: 'P3: Dribbling Warm-up',
    //             subTitle: 'Dynamic Warmup Pro 03 Dribbling',
    //             actionType: SubfolderPageUrl.videoSeries,
    //           },
    //           {
    //             title: 'P4: Juggling Warm-up',
    //             subTitle: 'Dynamic Warmup Pro 04 Juggling',
    //             actionType: SubfolderPageUrl.videoSeries,
    //           },
    //           {
    //             title: 'P5: Passing Warm-up',
    //             subTitle: 'Dynamic Warmup Pro 05 Part',
    //             actionType: SubfolderPageUrl.videoSeries,
    //           },
    //         ],
    //       }]
    //     } ,{
    //       title: 'Quick Sessions',
    //       subTitle: '',
    //       actionType: 'SET_QUICK_SESSIONS_DATA',
    //       children: [
    //         {
    //           title: 'Quick Sessions',
    //           subTitle: '',
    //           actionType: 'SET_QUICK_SESSIONS_CHILDREN_DATA',
    //           children: [
    //             {
    //               title: 'P1: Juggling Beginner',
    //               subTitle: 'Choice of Juggle Master 01 Begin',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Juggling Intermediate',
    //               subTitle: 'Choice of Juggle Master 02 Inter',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Juggling Advance',
    //               subTitle: 'Choice of Juggle Master 03 Adv',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Ball Mastery',
    //               subTitle: '(Intermed to Adv) Ball Mastery',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P5: Dribbling',
    //               subTitle: '(Interm to Adv) Dribbling Drills',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P6: Passing & Receiving',
    //               subTitle: 'Choose Passing Wall & Partner',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P7: 1v1 Moves',
    //               subTitle: 'Select 1v1 Moves',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P8: Finishing',
    //               subTitle: 'Select Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Juggling & Aerial Control',
    //       subTitle: '',
    //       actionType: 'SET_JUGGLING_AND_AERIAL_CONTROL_DATA',
    //       children: [
    //         {
    //           title: 'Juggling Warm-up',
    //           subTitle: '',
    //           actionType: 'SET_JUGGLING_WARMPUP_DATA',
    //           children: [
    //             {
    //               title: 'P1: Juggling Warm-up',
    //               subTitle: '(Aerial) Warm-up Juggle',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Thigh Juggling Beginner',
    //           subTitle: '',
    //           actionType: 'SET_THIGH_JUGGLING_BEGINNER_DATA',
    //           children: [
    //             {
    //               title: 'P1: Thigh Juggling',
    //               subTitle: '2 Footed Thigh Juggling 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Thigh Juggling',
    //               subTitle: '2 Footed Thigh Juggling 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Thigh Juggling',
    //               subTitle: '2 Footed Thigh Juggling 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Kick & Catch Juggling Beginner',
    //           subTitle: '',
    //           actionType: 'SET_KICK_CATCH_JUGGLING_BEGINNER_DATA',
    //           children: [
    //             {
    //               title: 'P1: Kick & Catch Juggling',
    //               subTitle: 'Catch & Hit Juggle 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Kick & Catch Juggling',
    //               subTitle: 'Catch & Hit Juggle 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Kick & Catch Juggling',
    //               subTitle: 'Catch & Hit Juggle 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Kick & Catch Juggling',
    //               subTitle: 'Catch & Hit Juggle 04 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Juggle Bounce Beginner',
    //           subTitle: '',
    //           actionType: 'SET_JUGGLE_BOUNCE_BEGINNER_DATA',
    //           children: [
    //             {
    //               title: 'P1: Juggle Bounce',
    //               subTitle: 'Beginner Juggle & Bounce 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Juggle Bounce',
    //               subTitle: 'Beginner Juggle & Bounce 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Intermediate Juggling',
    //           subTitle: '',
    //           actionType: 'SET_INTERMEDIATE_JUGGLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Juggle Bounce and Kick & Catch Juggling',
    //               subTitle: '1 Intermed Juggle Master 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Continuous Juggle Bounce & Multiple Kick & Catch Juggling',
    //               subTitle: '2 Intermed Juggle Master 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Continuous Juggling',
    //               subTitle: '2 Intermed Juggle Master 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Advanced Juggling Part One',
    //           subTitle: '',
    //           actionType: 'SET_ADVANCED_JUGGLING_PART_ONE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Isolated Feet, Stalls, Bounce and Overhead Juggling',
    //               subTitle: 'Advanced Juggling 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Continuous, Seated, Isolated Feet and Inside/Outside Feet',
    //               subTitle: 'Advanced Juggling 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Advanced Juggling Part Two',
    //           subTitle: '',
    //           actionType: 'SET_ADVANCED_JUGGLING_PART_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Shoulder, Thigh, Hopping, Ladder, Figure 8 and More',
    //               subTitle: 'Advanced Moving Juggling 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Walking, Running, Flicks and More',
    //               subTitle: 'Advanced Moving Juggling 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Advanced Juggling Part Three',
    //           subTitle: '',
    //           actionType: 'SET_ADVANCED_JUGGLING_PART_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Low Low High, Circle, Figure 8 and More',
    //               subTitle: 'Flicks | Juggling 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Various Parts of Feet, Freestyle and More',
    //               subTitle: 'Flicks | Juggling 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Tight Square, Body, Overhead and More',
    //               subTitle: 'Flicks | Juggling 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Juggling With Different Sized Balls',
    //           subTitle: '',
    //           actionType: 'SET_JUGGLING_WITH_DIFFERENT_SIZED_BALLS_DATA',
    //           children: [
    //             {
    //               title: 'P1: Size One Ball Juggling',
    //               subTitle: 'Ball of Size One',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Tennis Ball Juggling',
    //               subTitle: 'Big Tennis Ball Pro',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Mixed Ball Juggling',
    //               subTitle: 'Balls of Mixed-size',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Partner Toss, Volley and Aerial Control',
    //           subTitle: '',
    //           actionType: 'SET_PARTNER_TOSS_VOLLEY_AND_AERIAL_CONTROL_DATA',
    //           children: [
    //             {
    //               title: 'P1: Laces Volley & Half Volley',
    //               subTitle: 'Aerial Toss Part 1 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Other Parts of Feet Volley & Half Volley',
    //               subTitle: 'Aerial Toss Part 1 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Thigh and Feet Volley & Half Volley',
    //               subTitle: 'Aerial Toss Part 2 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Chest and Feet Volley & Half Volley',
    //               subTitle: 'Aerial Toss Part 2 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P5: Volley & Half Volley with Movement',
    //               subTitle: 'Aerial Toss Part 2 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Wall Juggling',
    //           subTitle: '',
    //           actionType: 'SET_WALL_JUGGLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Wall Juggling Exercises',
    //               subTitle: 'Aero Wall Control',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Partner Juggling',
    //           subTitle: '',
    //           actionType: 'SET_PARTNER_JUGGLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Various Partner Juggling Exercises',
    //               subTitle: 'Aero Juggling with Partner',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Body Control, Dribbling and Finishing with Juggling',
    //           subTitle: '',
    //           actionType: 'SET_BODY_CONTROL_DRIBBLING_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Chest, Thigh and Shoulder and More',
    //               subTitle: 'Body Control Juggle Master',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Aerial Control and Dribbling',
    //               subTitle: 'Aerial Control Dribbling Escape',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Aerial Control and Finishing',
    //               subTitle: 'Aerial Control Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Juggling Timed Challenges',
    //           subTitle: '',
    //           actionType: 'SET_JUGGLING_TIMED_CHALLENGES_DATA',
    //           children: [
    //             {
    //               title: 'P1: Size 4 and 5 Sized Ball Challenges',
    //               subTitle: '(Timed) Juggle Challenge 01 Reg',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Size One Ball Challenges',
    //               subTitle: '(Timed) Juggle Challenge 02 One',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Tennis Ball Challenges',
    //               subTitle: '(Timed) Juggle Challenge 3Tennis',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Thigh Juggling Challenges',
    //               subTitle: '(Timed) Juggle Challenge 4 Thigh',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Ball Mastery',
    //       subTitle: '',
    //       actionType: 'SET_BALL_MASTERY_DATA',
    //       children: [
    //         {
    //           title: '1,000 Touch Ball Mastery - Part One - One',
    //           subTitle: '',
    //           actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_ONE_ONE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Toe Taps, Tick Tocks, Stationary, Pause & Moving',
    //               subTitle: '1000 Touch P1 Part 1 Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Toe Taps, Tick Tocks, Sole Rolls with Movement',
    //               subTitle: '1000 Touch P1 Part 1 Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Tick Tocks, Body Feints, Inside/Outsides and More',
    //               subTitle: '1000 Touch P1 Part 1 Part 03',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Tick Tocks, Toe Taps, Inside/Outside, L-Behinds and More',
    //               subTitle: '1000 Touch P1 Part 1 Part 04',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: '1,000 Touch Ball Mastery - Part One - Two',
    //           subTitle: '',
    //           actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_ONE_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Sole Moves, Dribbles, Stepovers, Pull Backs and More',
    //               subTitle: '1000 Touch P1 Part 2 Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Pull Push, Inside Outside, Scissors, Stepover and More',
    //               subTitle: '1000 Touch P1 Part 2 Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Scissors, Stepover, Sole, Hop Taps and More',
    //               subTitle: '1000 Touch P1 Part 2 Part 03',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: '1,000 Touch Ball Mastery - Part One - Three',
    //           subTitle: '',
    //           actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_ONE_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: V-Pulls, Dribbles, Scissors, Brazilian Toe Taps and More',
    //               subTitle: '1000 Touch P1 Part 3 Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: V-Pulls, Scissors, Toe Drags, SoleFlicks and More',
    //               subTitle: '1000 Touch P1 Part 3 Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: '1,000 Touch Ball Mastery - Part Two - One',
    //           subTitle: '',
    //           actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_TWO_ONE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Chops, L-Behind,  Toe Pulls and More',
    //               subTitle: '1000 Touch P2 Part 1 Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: L-Behind, Outside/Inside, Cruyff, La Croqueta and More',
    //               subTitle: '1000 Touch P2 Part 1 Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: '1,000 Touch Ball Mastery - Part Two - Two',
    //           subTitle: '',
    //           actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_TWO_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Sole Roll V-Pulls, Curls, Scissors, Stepover and More',
    //               subTitle: '1000 Touch P2 Part 2 Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Tick Tocks and Roll, Sole, Cruyff, Pull Push and More',
    //               subTitle: '1000 Touch P2 Part 2 Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: '1,000 Touch Ball Mastery - Part Two - Three',
    //           subTitle: '',
    //           actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_TWO_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Pull Backs, Directional Control, L-Behinds and More',
    //               subTitle: '1000 Touch P2 Part 3 Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Toe Taps, Tick Tocks, L-Behinds, Directional Control and More',
    //               subTitle: '1000 Touch P2 Part 3 Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Double Scissors, L-Behinds, V-Pulls, Dribbling and More',
    //               subTitle: '1000 Touch P2 Part 3 Part 03',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'One Cone Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_ONE_CONE_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Hop Taps, U Patterns & More',
    //               subTitle: '1 Cone Pro 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Hops Taps, Sole Flicks, V-Pulls',
    //               subTitle: '1 Cone Pro 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Fake Pass, L-Behind, Triangle Touch & More',
    //               subTitle: '1 Cone Pro 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Maradona, La Croqueta, Lateral Dribbles & More',
    //               subTitle: '1 Cone Pro 04 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Two Cone Ball Mastery Part One',
    //           subTitle: '',
    //           actionType: 'SET_TWO_CONE_BALL_MASTERY_PART_ONE_DATA',
    //           children: [
    //             {
    //               title: 'P1: La Croqueta, Sole Roll, Outside/Inside L-Behind & More',
    //               subTitle: 'Dual Cone Pro P1 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Sole Role, Inside/Outside L-Behind & More',
    //               subTitle: 'Dual Cone Pro P1 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Two Cone Ball Mastery Part Two',
    //           subTitle: '',
    //           actionType: 'SET_TWO_CONE_BALL_MASTERY_PART_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: L-Behind, Toe Drag, Pull Back, V-Pull & More',
    //               subTitle: 'Dual Cone Pro P2 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Pull Back, U-Patterns, V-Pull, Hop Tap & More',
    //               subTitle: 'Dual Cone Pro P2 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Two Cone Ball Mastery Part Three',
    //           subTitle: '',
    //           actionType: 'SET_TWO_CONE_BALL_MASTERY_PART_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Pull Backs, Triangle Touch, Sole Flicks, Pull Push & More',
    //               subTitle: 'Dual Cone Pro P3 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: L-Behind, V-Pulls, Pull Backs, Single Feet & More',
    //               subTitle: 'Dual Cone Pro P3 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Full Review of Select Two Cone Moves',
    //               subTitle: 'Dual Cone Pro P3 03 Full Review',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Four Cone Essential Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_FOUR_CONE_ESSENTIAL_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Pull Backs, V-Pulls, La Croqueta & More',
    //               subTitle: 'Four Cone Essential 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Cruyffs, U-Patterns, Pull Backs, Toe Taps, V-Pulls & More',
    //               subTitle: 'Four Cone Essential 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Three to Six Cone Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_THREE_TO_SIX_CONE_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Three Cone Push Pull, Sole Pyramid, V-Pulls & More',
    //               subTitle: 'Four Cone Pro P1 Three Cone',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Four Cone Hop Tap, Tick Tocks, Weaves & More',
    //               subTitle: 'Four Cone Pro P2 Four Cone',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Six Cone Pull Backs, Directional Control, Spinning & More',
    //               subTitle: 'Four Cone Pro P3 Six Cone',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'More Ball Mastery',
    //       subTitle: '',
    //       actionType: 'SET_MORE_BALL_MASTERY_DATA',
    //       children: [
    //         {
    //           title: 'Lateral Movement Ball Mastery - Part One',
    //           subTitle: '',
    //           actionType: 'SET_LATERAL_MOVEMENT_BALL_MASTERY_PART_ONE_DATA',
    //           children: [
    //             {
    //               title: "P1: Pull Backs, Sole Rolls, U's, La Croqueta and more",
    //               subTitle: '1 Lateral Movement 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Toe Taps, Tick Tocks, V-Pulls, Freestyle and more',
    //               subTitle: '1 Lateral Movement 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Lateral Movement Ball Mastery - Part Two',
    //           subTitle: '',
    //           actionType: 'SET_LATERAL_MOVEMENT_BALL_MASTERY_PART_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Dribbling Combinations, V-Pulls, Turns and more',
    //               subTitle: '2 Lateral Movement 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Dribbling Combinations, Scissors, Cruyffs, and more',
    //               subTitle: '2 Lateral Movement 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Lateral Movement Ball Mastery - Part Three',
    //           subTitle: '',
    //           actionType: 'SET_LATERAL_MOVEMENT_BALL_MASTERY_PART_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Dribbling, Juggling, V-Pulls and more',
    //               subTitle: '3 Lateral Movement 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Stanley Matthews, L-Behind, Sole Rolls and more',
    //               subTitle: '3 Lateral Movement 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Dynamic Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_DYNAMIC_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Sole Rolls, Inside/Outsides, Toe Taps and more',
    //               subTitle: 'Dynamic Ball Pro 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Tick Tocks, Elastico, Stepover, Scissors and more',
    //               subTitle: 'Dynamic Ball Pro 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Tick Tocks, Stanley Matthews, Pull Push and more',
    //               subTitle: 'Dynamic Ball Pro 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Midfielder Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_MIDFIELDER_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: V-Pulls, Directional Control, Pull Backs and more.',
    //               subTitle: '(Midfield Ball Mastery) 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Large V-Pulls, Directional Control, L-Behinds and more',
    //               subTitle: '(Midfield Ball Mastery) 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Tick Tocks, Dribbling, L-Behinds, Freesytle and more',
    //               subTitle: '(Midfield Ball Mastery) 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Ball Mastery & Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_BALL_MASTERY_AND_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Diagonal Dribbling, Scissors, Turns, Cuts and more',
    //               subTitle: 'Ball Mastery & Dribbling Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Diagonal Dribbling, V-Pull, Freestyle, Maradonna and more',
    //               subTitle: 'Ball Mastery & Dribbling Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Sole Roll, Tick Tocks, Freestyle, Stepover and more',
    //               subTitle: 'Ball Mastery & Dribbling Part 03',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Ball Mastery & Tennis Ball',
    //           subTitle: '',
    //           actionType: 'SET_BALL_MASTERY_AND_TENNIS_BALL_DATA',
    //           children: [
    //             {
    //               title: 'Toe Taps, Tick Tocks, Inside/Outside & More',
    //               subTitle: 'Ball Mastery & Tennis Ball',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Tight Space Dribbling',
    //       subTitle: '',
    //       actionType: 'SET_TIGHT_SPACE_DRIBBLING_DATA',
    //       children: [
    //         {
    //           title: 'Figure 8 Dribbling & Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_FIGURE_EIGHT_DRIBBLING_AND_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Figure 8 Close Control',
    //               subTitle: 'Figure 8 Close Control 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Figure 8 Close Control',
    //               subTitle: 'Figure%208%20Close%20Control%20%2002%20Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Figure 8 Essentials',
    //               subTitle: 'Figure 8 Essential Drill 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Figure 8 Longer Distance',
    //               subTitle: 'Figure 8 Long Distance Drills',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P5: Lateral Figure 8 Drills',
    //               subTitle: 'Figure 8 Lateral Drills 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P6: Figure 8 1v1 Moves',
    //               subTitle: 'Figure 8 1v1 Moves 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P7: Figure 8 & Ball Mastery',
    //               subTitle: 'Figure 8 Ball Mastery 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Tight Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_TIGHT_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Three, Four & Tight Dribbling',
    //               subTitle: 'Fast Speed Tight Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Tight Box Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_TIGHT_BOX_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Tight Box Dribbling - Weaves, Sole Rolls and Turns',
    //               subTitle: 'Box Dribbling Pro Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Tight Box Dribbling - Freestyle & Turns',
    //               subTitle: 'Box Dribbling Pro Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Five Star Tight Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_FIVE_STAR_TIGHT_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Five Star Dribbling - Weaves, Sole Rolls and more',
    //               subTitle: 'Five Star Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Body Feints & Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_BODY_FEINTS_AND_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Feints, Sole Rolls, Dribbling & more',
    //               subTitle: 'Body Feint Pro 01 Body Feint',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Feints, Chops, Figure 8 & more',
    //               subTitle: 'Body Feint Pro 01 Body Feint',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Feints, Freestyle & Dribbling',
    //               subTitle: 'Body Feint Pro 03 Body Feint',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Three Cone Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_THREE_CONDE_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Dribbling, One and Two Touch Weaves',
    //               subTitle: '3 Cone Dribbling 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Dribbling, 360 Turns, La Croqueta and more',
    //               subTitle: '3 Cone Dribbling 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Freestyle Dribbling & Change Direction',
    //           subTitle: '',
    //           actionType: 'SET_FREESTYLE_DRIBBLING_AND_CHANGE_DIRECTION_DATA',
    //           children: [
    //             {
    //               title: 'P1: Tight Box Freestyle, Cuts & More',
    //               subTitle: 'Change Route & Freestyle Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Dribbling, 360 Turns, La Croqueta and more',
    //               subTitle: 'P2: Tight Box Freestyle, Scissors, Stepovers & More',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Dribbling & Change Direction',
    //       subTitle: '',
    //       actionType: 'SET_DRIBBLING_AND_CHANGE_DIRECTION_DATA',
    //       children: [
    //         {
    //           title: 'Dribbling & Change Direction Part One & Two',
    //           subTitle: '',
    //           actionType: 'SET_DRIBBLING_AND_CHANGE_DIRECTION_PART_ONE_AND_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Inside/Outside, Feints, L-Beind Scissors & more',
    //               subTitle: 'Fast Speed Dribble Master P1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Lateral Dribbling, Speed Dribbling, Hops & more',
    //               subTitle: 'Fast Speed Dribble Master P2 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Dribbling & Change Direction Part Three & Four',
    //           subTitle: '',
    //           actionType: 'SET_DRIBBLING_AND_CHANGE_DIRECTION_PART_THREE_AND_FOUR_DATA',
    //           children: [
    //             {
    //               title: 'P1: Sole Rolls and Outside Foot Change Direction',
    //               subTitle: 'Fast Speed Dribble Master P3 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Multiple Sole Rolls and Outside Foot Change Direction',
    //               subTitle: 'Fast Speed Dribble Master P3 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Diagonal Dribbling, Tick Tocks, L-Behinds & more',
    //               subTitle: 'Fast Speed Dribble Master P4 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Diagonal Dribbling, Cuts, Hps, L-Behinds & more',
    //               subTitle: 'Fast Speed Dribble Master P4 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Line Cone & Cone Slalom',
    //       subTitle: '',
    //       actionType: 'SET_LINE_CONE_AND_CONE_SLALOM_DATA',
    //       children: [
    //         {
    //           title: 'Line Cone Dribbling Part One & Two',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_DRIBBLING_PART_ONE_AND_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Speed Dribbling, Tight Control, Weave and more',
    //               subTitle: '1 Line Cone 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Weaves, Stops, Sole Rolls, V-Pulls and more',
    //               subTitle: '1 Line Cone 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Taps, Rolls, La Croqueta, V-Pulls and more',
    //               subTitle: '2 Line Cone 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Taps Backward, Weaves, V-Pull Weaves and more',
    //               subTitle: '2 Line Cone 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Line Cone Dribbling Part Three & Four',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_DRIBBLING_PART_THREE_AND_FOUR_DATA',
    //           children: [
    //             {
    //               title: 'P1: Tap Weaves, Outside and Inside Foot Dribbling and more',
    //               subTitle: '3 Line Cone 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Two Touch Weaves, Single Foot Rolls and more',
    //               subTitle: '3 Line Cone 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Sole Rolls, Speed Dribbling, Cuts and more',
    //               subTitle: '4 Line Cone 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Speed Dribbling, Turns, Hops and more',
    //               subTitle: '4 Line Cone 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Cone Weave Slalom',
    //           subTitle: '',
    //           actionType: 'SET_CONE_WEAVE_SLALOM_DATA',
    //           children: [
    //             {
    //               title: 'P1: Single Foot Weaves, Sole Roll, 360 Turns and more',
    //               subTitle: 'Cone Slalom Pro 01 Part 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Line Cone and Slalom Combination',
    //               subTitle: 'Cone Slalom Pro 02 Line & Slalom',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Feints, Stops, V-Pulls and more',
    //               subTitle: 'Cone Slalom Pro 03 Part 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Sole Rolls, V-Pulls, Weaves and more',
    //               subTitle: 'Cone Slalom Pro 04 Part 4',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P5: L-Behinds, Ineista, Stepover, Maradona and more',
    //               subTitle: 'Cone Slalom Pro 05 Part 5',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P6: Stanley Matthews, Passing, Sole Rolls and more',
    //               subTitle: 'Cone Slalom Pro 06 Part 6',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Line Cone & Slalom Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_AND_SLALOM_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: One and Two Touch Weaves and more',
    //               subTitle: 'Cone Line Slalom 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Single Foot Dribbling, Cuts, Inside Turns and more',
    //               subTitle: 'Cone Line Slalom 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Rectangle Weaves, Turns, Speed Dribbling and more',
    //               subTitle: 'Cone Line Slalom 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Mixed Distances Line Cone Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_MIXED_DISTANCES_LINE_CONE_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Snake Lane Dribbling, Sole Rolls, Weaves and more',
    //               subTitle: 'Cone Line Mixed Distance',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Speed, Obstacle & Lane Dribbling',
    //       subTitle: '',
    //       actionType: 'SET_SPEED_OBSTACLE_AND_LANE_DRIBBLING_DATA',
    //       children: [
    //         {
    //           title: 'Speed Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_SPEED_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Speed Dribbling & Turns',
    //               subTitle: 'Fast Dribbling & Turns',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Speed Dribbling & Weaves',
    //               subTitle: 'Fast Dribbling & Weaves',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Speed Dribbling & Shuttle Run',
    //               subTitle: 'Fast Dribbling & Fitness',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Speed Dribbling & Hopping',
    //               subTitle: 'Fast Dribbling Hop Taps | Other',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Obstacle and Lane Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_OBSTACLE_AND_LANE_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Obstacle Course Dribbling',
    //               subTitle: 'Course of Obstacle Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Lateral Lane Dribbling',
    //               subTitle: 'Fast Speed 07 Lateral Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Snake Lane Dribbling',
    //               subTitle: 'Fast Speed 08 Lane Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Shape Pattern Dribbling',
    //       subTitle: '',
    //       actionType: 'SET_SHAPE_PATTERN_DRIBBLING_DATA',
    //       children: [
    //         {
    //           title: 'Box Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_BOX_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Foundation Box - Turns, Sole Roll, Dribbling and more',
    //               subTitle: 'Foundation Box Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: X Box - Figure 8, Weave, Speed Dribbling and more',
    //               subTitle: '(X)Box Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Close Box - Dribble Weave, Speed Dribbling and more',
    //               subTitle: 'Close Box Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: 1v1 Box Moves - 1v1 Moves around Box and more',
    //               subTitle: '1v1 Moves & More',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Five Star Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_FIVE_STAR_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Tight Dribbling, Sole Roll, La Croqueta and more',
    //               subTitle: 'Five Star Pro 01 One to Two Feet',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Speed Dribbling, Weave, Inside/Outside Foot and more',
    //               subTitle: 'Five Star Pro 02 Five to 10 Feet',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Mix Distances Five Star Weaves and more',
    //               subTitle: 'Five Star Pro 03 Mixed Distances',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Dribble and Sprint Combinations',
    //               subTitle: 'Five Star Pro 04 Dribble+Sprint',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P5: Full Review Five Star',
    //               subTitle: 'Five Star Pro 05 Full Review',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'More Shape Pattern Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_MORE_SHAPE_PATTERN_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: T-Formation Dribbling',
    //               subTitle: 'Fast Speed 04 Dribble T-Form',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Circle Dribbling',
    //               subTitle: 'Fast Speed 06 Circle Dribbling',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Pentagon Dribbling',
    //               subTitle: 'Dribble Pentagon',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: W-Pattern Dribbling',
    //               subTitle: '(W)Pattern',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Freestyle, 1v1 & Shielding',
    //       subTitle: '',
    //       actionType: 'SET_FREESTYLE_1V1_AND_SHIELDING_DATA',
    //       children: [
    //         {
    //           title: 'Freestyle Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_FREESTYLE_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Freesytle Dribbling',
    //               subTitle: 'Brief Freestyle 01 Free',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Freesytle Dribbling',
    //               subTitle: 'Brief Freestyle 02 Free',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Circle 1v1, Tag & Shielding',
    //           subTitle: '',
    //           actionType: 'SET_CIRCLE_1V1_TAG_AND_SHIELDING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Circle Dribbling, Tag & 1v1',
    //               subTitle: 'Circle Dribbling Tag & 1v1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Dribbling & Shielding',
    //               subTitle: 'Dribbling & Shielding',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Quick Turns & Escape',
    //       subTitle: '',
    //       actionType: 'SET_QUICK_TURNS_AND_ESCAPE_DATA',
    //       children: [
    //         {
    //           title: 'Quick Turns & Change Direction',
    //           subTitle: '',
    //           actionType: 'SET_QUICK_TURNS_AND_CHANGE_DIRECTION_DATA',
    //           children: [
    //             {
    //               title: 'P1: Inside, Outside, Cruyff, L-Behind & more',
    //               subTitle: 'Change Direction P1 01 Turns P1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Stepover, Scissors, Roulette, Tick Tock & more',
    //               subTitle: 'Change%20Direction%20P1%20%2002%20Turns%20P2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Speed Dribbling & Foundational Turns',
    //               subTitle: 'Change Direction P2 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Full Review of Essential Turns',
    //               subTitle: 'Change Direction P2 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Turning & Escaping Pressure ',
    //           subTitle: '',
    //           actionType: 'SET_TURNING_AND_ESCAPING_PRESSURE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Freesytle Dribbling, Stepover, Spinning and more',
    //               subTitle: 'Escape Pressure Pro 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: L-Behind, Scissors, Stepovers and more',
    //               subTitle: 'Escape Pressure Pro 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Short & Double Turns',
    //       subTitle: '',
    //       actionType: 'SET_SHORT_AND_DOUBLE_TURNS_DATA',
    //       children: [
    //         {
    //           title: 'Line Cone & Short/Long Turns',
    //           subTitle: '',
    //           actionType: 'SET_TURNING_AND_ESCAPING_PRESSURE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Line Cone - Foundational Turns',
    //               subTitle: 'Cone Line & Turns',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Short & Long Turns',
    //               subTitle: '(Short Long) Turns',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Double Turns',
    //           subTitle: '',
    //           actionType: 'SET_DOUBLE_TURNS_DATA',
    //           children: [
    //             {
    //               title: 'P1: Inside, Outside, Single, Both Feet and more',
    //               subTitle: 'Double Turns 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Feints, Outside, Rolls, Pull Backs and more',
    //               subTitle: 'Double Turns 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Curl Spin, Pull Backs, Cruyff and more',
    //               subTitle: 'Double Turns 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: L-Behind, Cruyff, Curl Spin, L-Behind and more',
    //               subTitle: 'Double Turns 04 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Wall Passing',
    //       subTitle: '',
    //       actionType: 'SET_WALL_PASSING_DATA',
    //       children: [
    //         {
    //           title: 'First Touch Rebounder Part 1',
    //           subTitle: '',
    //           actionType: 'SET_FIRST_TOUCH_REBOUNDER_PART_1_DATA',
    //           children: [
    //             {
    //               title: 'P1: One & Two Touches, Inside/Outside Foot & more',
    //               subTitle: 'First Touch Pro P1 01 Part One',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Two Touches, Laces, Inside/Outside & more',
    //               subTitle: 'First Touch Pro P1 02 Part Two',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'First Touch Rebounder Part 2',
    //           subTitle: '',
    //           actionType: 'SET_FIRST_TOUCH_REBOUNDER_PART_2_DATA',
    //           children: [
    //             {
    //               title: 'P1: Hopping, Sole Roll, Passing & more',
    //               subTitle: 'First Touch Pro P2 01 Part One',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Sole Roll, One Cone, L-Behind & more',
    //               subTitle: 'First Touch Pro P2 02 Part Two',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'First Touch Rebounder Part 3',
    //           subTitle: '',
    //           actionType: 'SET_FIRST_TOUCH_REBOUNDER_PART_3_DATA',
    //           children: [
    //             {
    //               title: 'P1: Cruyff, Step Over, Scissors & more',
    //               subTitle: 'First Touch Pro P3 01 Part One',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Aerial Control off Wall',
    //               subTitle: 'First Touch Pro P3 02 Aerial',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: ' Core Passing',
    //       subTitle: '',
    //       actionType: 'SET_CORE_PASSING_DATA',
    //       children: [
    //         {
    //           title: 'Foundation Passing',
    //           subTitle: '',
    //           actionType: 'SET_FOUNDATION_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Foundation Passing Series',
    //               subTitle: 'Foundation Passing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'One Cone Passing',
    //           subTitle: '',
    //           actionType: 'SET_ONE_CONE_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: One Cone Passing Part 1',
    //               subTitle: '1 Cone Passing 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: One Cone Passing Part 2',
    //               subTitle: '1 Cone Passing 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Partner Passing Part One',
    //           subTitle: '',
    //           actionType: 'SET_PARTNER_PASSING_PART_ONE_DATA',
    //           children: [
    //             {
    //               title: 'P1: One & Two Touch, Dead Stop & more',
    //               subTitle: '2 Touch Passing Partner 01 Pass',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: One & Two Touches, Traps & more',
    //               subTitle: '2 Touch Passing Partner 02 Pass',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Partner Passing Part Two',
    //           subTitle: '',
    //           actionType: 'SET_PARTNER_PASSING_PART_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Directional Control, Inside/Outside & more',
    //               subTitle: 'Directional Ctrl Sole 01 Pass',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Sole Rolls, Cone Shuffles & more',
    //               subTitle: 'Directional Ctrl Sole 02 Pass',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Partner Passing Part Three',
    //           subTitle: '',
    //           actionType: 'SET_PARTNER_PASSING_PART_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Full Review Passing Drills Part 1',
    //               subTitle: '1 Cone Movement More 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Full Review Passing Drills Part 2',
    //               subTitle: '1 Cone Movement More 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Passing & Moving',
    //           subTitle: '',
    //           actionType: 'SET_PASSING_AND_MOVING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Passing & Moving Drills',
    //               subTitle: 'Area Running',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Passing Warmup',
    //               subTitle: 'Dynamic Warmup Pro 05 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Passing Games',
    //               subTitle: 'Passing Games',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Cone & Lateral Pass',
    //       subTitle: '',
    //       actionType: 'SET_CONE_AND_LATERAL_PASS_DATA',
    //       children: [
    //         {
    //           title: 'Lateral Line Cone Passing',
    //           subTitle: '',
    //           actionType: 'SET_LATERAL_LINE_CONE_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Lateral Line Cone Passing Series',
    //               subTitle: 'Cone Line Lateral',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Ladder Passing',
    //           subTitle: '',
    //           actionType: 'SET_LADDER_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Ladder Passing Series',
    //               subTitle: 'Footway Passing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Line Cone Passing',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Line Cone Passing Part 1',
    //               subTitle: 'Cone Line Passing 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Line Cone Passing Part 2',
    //               subTitle: 'Cone Line Passing 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Long Passing & Other',
    //       subTitle: '',
    //       actionType: 'SET_LONG_PASSING_AND_OTHER_DATA',
    //       children: [
    //         {
    //           title: 'Long Distance Passing',
    //           subTitle: '',
    //           actionType: 'SET_LONG_DISTANCE_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Low Driven Ball Passing',
    //               subTitle: 'Distant Passing 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Passing & Sprinting',
    //           subTitle: '',
    //           actionType: 'SET_PASSING_AND_SPRINTING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Passing & Sprinting Series',
    //               subTitle: 'Cardio Passing Drills',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'T-Formation Passing',
    //           subTitle: '',
    //           actionType: 'SET_T_FORMATION_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: T-Formation Passing Part 1',
    //               subTitle: '(T)Formation Passing Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P1: T-Formation Passing Part 2',
    //               subTitle: '(T)Formation Passing Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Agility Rings Passing',
    //           subTitle: '',
    //           actionType: 'SET_AGILITY_RINGS_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Agility Rings Passing Part 1',
    //               subTitle: 'Agility Ring Passing Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P1: Agility Rings Passing Part 2',
    //               subTitle: 'Agility Ring Passing Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Circle Passing',
    //           subTitle: '',
    //           actionType: 'SET_CIRCLE_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Circle Passing Series',
    //               subTitle: 'Circle Passing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Five Star & Figure 8 1v1',
    //       subTitle: '',
    //       actionType: 'SET_FIVE_STAR_AND_FIGURE_8_1V1_DATA',
    //       children: [
    //         {
    //           title: 'Five Star 1v1 Moves - Part One',
    //           subTitle: '',
    //           actionType: 'SET_FIVE_STAR_1V1_MOVES_PART_ONE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Body Feints, Pull Backs, Scissors & more',
    //               subTitle: '1v1 Moves Pro P1 01 1v1 Part 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Double Scissors, Stepover, Ronaldo Chops & more',
    //               subTitle: '1v1 Moves Pro P1 02 1v1 Part 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Five Star 1v1 Moves - Part Two',
    //           subTitle: '',
    //           actionType: 'SET_FIVE_STAR_1V1_MOVES_PART_TWO_DATA',
    //           children: [
    //             {
    //               title: 'P1: Iniesta, Turns, Stepovers, Scissors & more',
    //               subTitle: '1v1 Moves Pro P2 01 1v1 Part 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Maradona, Turns, Ronaldo Chops & more',
    //               subTitle: '1v1 Moves Pro P2 02 1v1 Part 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Five Star 1v1 Moves - Part Three',
    //           subTitle: '',
    //           actionType: 'SET_FIVE_STAR_1V1_MOVES_PART_THREE_DATA',
    //           children: [
    //             {
    //               title: 'P1: L-Behinds, Sole Rolls, Scissors & more',
    //               subTitle: '1v1 Moves Pro P3 01 1v1 Part 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Full Review of Key Moves',
    //               subTitle: '1v1 Moves Pro P3 02 1v1 Part 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Figure 8 1v1 Moves',
    //           subTitle: '',
    //           actionType: 'SET_FIVE_STAR_1V1_MOVES_DATA',
    //           children: [
    //             {
    //               title: 'P1: Sole Rolls, La Croqueta, Ronaldo Chops & more',
    //               subTitle: 'Figure 8 Moves 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Speed Dribbling, Chops, Turns & more',
    //               subTitle: 'Figure 8 Moves 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Rapid Fire & Directional Control',
    //       subTitle: '',
    //       actionType: 'SET_RAPID_FIRE_AND_DIRECTIONAL_CONTROL_DATA',
    //       children: [
    //         {
    //           title: 'Rapid Fire',
    //           subTitle: '',
    //           actionType: 'SET_RAPID_FIRE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Quick Shots, Back Heel, Volleys & more',
    //               subTitle: 'Fire Rapid Pro 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Stationary Balls, Sprinting & more',
    //               subTitle: 'Fire Rapid Pro 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Half Circle, Aerial Control & more',
    //               subTitle: 'Fire Rapid Pro 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Directional Control - Back Towards Goal',
    //           subTitle: '',
    //           actionType: 'SET_DIRECTIONAL_CONTROL_BACK_TOWARDS_GOAL_DATA',
    //           children: [
    //             {
    //               title: 'P1: Body Feints, Inside, Outside Foot Turns & more',
    //               subTitle: 'Directional Control 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Body Feints, Inside, Outside Foot Stepovers & more',
    //               subTitle: 'Directional Control 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Dribbling, Directional Control & Finish',
    //           subTitle: '',
    //           actionType: 'SET_DIRECTIONAL_CONTROL_AND_FINISH_DATA',
    //           children: [
    //             {
    //               title: 'P1: Dribbling in Space, Directional Control & Finish',
    //               subTitle: 'Directional Control 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: '1v1 & Dribbling',
    //       subTitle: '',
    //       actionType: 'SET_1V1_AND_DRIBBLING_DATA',
    //       children: [
    //         {
    //           title: '1v1 Moves & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_1V1_MOVES_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Body Feints, Scissors, Stepovers & more',
    //               subTitle: '1vs1 Moves & Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Attacking Winger Finishing',
    //           subTitle: '',
    //           actionType: 'SET_ATTACKING_WINGER_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Scissors, Stepovers, Body Feints & more',
    //               subTitle: 'Attacking Winger',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Line Cone Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Line Cone Dribbling Weaves & Finishing',
    //               subTitle: 'Cone Line Dribbling Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Freestyle Dribbling & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_FREESTYLE_DRIBBLING_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Aerial Control, Maze Dribbling & Finishing',
    //               subTitle: 'Freestyle Dribbling Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: '1v1 Duels & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_1V1_DUELS_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Keeper vs Striker 1v1 Duel',
    //               subTitle: 'Battle Finishing Part 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Race to the Ball 1v1 Duel',
    //               subTitle: 'Battle Finishing Part 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Lateral 1v1 & Finishing Duel',
    //               subTitle: 'Battle Finishing Part 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Dribble, Pass & Shoot',
    //       subTitle: '',
    //       actionType: 'SET_DRIBBLE_PASS_AND_SHOOT_DATA',
    //       children: [
    //         {
    //           title: 'Passing & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_PASSING_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Partner Passing Warmup & Finishing',
    //               subTitle: 'Passing Warmup Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Partner Passing & Finishing',
    //               subTitle: 'Pass & Shoot Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Cone Slalom Dribbling & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_CONE_SLALOM_DRIBBLING_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Cone Slalom Dribbling, Sprinting & Finish',
    //               subTitle: 'Cone Slalom Dribbling 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Cone Slalom Dribbling, Control & Finish',
    //               subTitle: 'Cone Slalom Dribbling 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Line Cone Dribbling & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_DRIBBLING_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Line Cone Dribbling Weaves & Finishing',
    //               subTitle: 'Cone Line Dribbling Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Line Cone Dribble Chase',
    //           subTitle: '',
    //           actionType: 'SET_LINE_CONE_DRIBBLE_CHASE_DATA',
    //           children: [
    //             {
    //               title: 'P1: Line Cone Dribble Chase Series',
    //               subTitle: 'Cone Chase Dribbling Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Aerial & Other',
    //       subTitle: '',
    //       actionType: 'SET_AERIAL_AND_OTHER_DATA',
    //       children: [
    //         {
    //           title: 'Aerial Control & Finishing',
    //           subTitle: '',
    //           actionType: 'SET_AERIAL_CONTROL_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Juggling Turn & Finish',
    //               subTitle: 'Aerial Control Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Aerial Control, 1v1 & Finishing',
    //               subTitle: 'Aerial Control, 1v1 Finishing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Crossing and Finishing',
    //           subTitle: '',
    //           actionType: 'SET_CROSSING_AND_FINISHING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Long Wide Passes & Finish - Part 1',
    //               subTitle: 'Crossing and Passing 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Long Wide Passes & Finish - Part 2',
    //               subTitle: 'Crossing and Passing 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Penalty Shootout',
    //           subTitle: '',
    //           actionType: 'SET_PENALTY_SHOOTOUT_DATA',
    //           children: [
    //             {
    //               title: 'P1: Penalty Shooting Using Both Feet',
    //               subTitle: 'Free Kick Penalty Shootout',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Ladders, Ball Mastery & Cardio',
    //       subTitle: '',
    //       actionType: 'SET_LADDERS_BALL_MASTERY_AND_CARDIO_DATA',
    //       children: [
    //         {
    //           title: 'Soccer Fitness, Ladders & Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_SOCCER_FITNESS_LADDERS_AND_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Soccer Fitness Part 1',
    //               subTitle: 'Endurance in Soccer Part 01',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Soccer Fitness Part 2',
    //               subTitle: 'Endurance in Soccer Part 02',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Soccer Fitness Part 3',
    //               subTitle: 'Endurance in Soccer Part 03',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Soccer Fitness Part 4',
    //               subTitle: 'Endurance in Soccer Part 04',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Agility Ladders & Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_AGILITY_LADDERS_AND_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Agility Ladders & Ball Mastery Part 1',
    //               subTitle: 'Agility Pro 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Agility Ladders & Ball Mastery Part 2',
    //               subTitle: 'Agility Pro 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Agility Ladders & Ball Mastery Part 3',
    //               subTitle: 'Agility Pro 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Jump Rope & Aerial Control',
    //           subTitle: '',
    //           actionType: 'SET_JUMP_ROPE_AND_AERIAL_CONTROL_DATA',
    //           children: [
    //             {
    //               title: 'P1: Jump Rope Series',
    //               subTitle: 'Cable Jump Master 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Agility Rings Sessions',
    //           subTitle: '',
    //           actionType: 'SET_AGILITY_RINGS_SESSIONS_DATA',
    //           children: [
    //             {
    //               title: 'P1: Agility Rings Sessions Part 1',
    //               subTitle: 'Agility Rings Pro 01 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Agility Rings Sessions Part 2',
    //               subTitle: 'Agility Rings Pro 02 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Agility Rings Sessions Part 3',
    //               subTitle: 'Agility Rings Pro 03 Part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Hurdles & Dribbling',
    //           subTitle: '',
    //           actionType: 'SET_HURDLES_AND_DRIBBLING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Hurdles and Speed Dribbling',
    //               subTitle: 'Fitness Hurdles Pro',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: 'Get Started & Next Level Plans',
    //       subTitle: '',
    //       actionType: 'SET_GET_STARTED_AND_NEXT_LEVEL_PLANS_DATA',
    //       children: [
    //         {
    //           title: 'Foundational Skills: Ball Mastery, Juggling, Passing',
    //           subTitle: '',
    //           actionType: 'SET_FOUNDATIONAL_SKILLS_BALL_MASTERY_JUGGLING_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Week One',
    //               subTitle: '30 Day Player Week 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Week Two',
    //               subTitle: '30 Day Player Week 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Week Three',
    //               subTitle: '30 Day Player Week 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Week Four',
    //               subTitle: '30 Day Player Week 4',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Juggle Master',
    //           subTitle: '',
    //           actionType: 'SET_JUGGLE_MASTER_DATA',
    //           children: [
    //             {
    //               title: 'P1: 30-Day Beginner',
    //               subTitle: '(Start) Juggle Challenge 01 Beg',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: 30-Day Intermediate',
    //               subTitle: '(Start) Juggle Challenge 02 Int',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: 30-Day Advanced',
    //               subTitle: '(Start) Juggle Challenge 03 Adv',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Rebounder & Wall Passing',
    //           subTitle: '',
    //           actionType: 'SET_REBOUNDER_AND_WALL_PASSING_DATA',
    //           children: [
    //             {
    //               title: 'P1: Week One',
    //               subTitle: '(Start) Wall Pass Pro 1 Week',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Week Two',
    //               subTitle: '(Start) Wall Pass Pro 2 Week',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Week Three',
    //               subTitle: '(Start) Wall Pass Pro 3 Week',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Week Four',
    //               subTitle: '(Start) Wall Pass Pro 4 Week',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Dribble Master',
    //           subTitle: '',
    //           actionType: 'SET_DRIBBLE_MASTER_DATA',
    //           children: [
    //             {
    //               title: 'P1: Week One',
    //               subTitle: 'Dribble Challenge Week 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Week Two',
    //               subTitle: 'Dribble Challenge Week 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Week Three',
    //               subTitle: 'Dribble Challenge Week 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Week Four',
    //               subTitle: 'Dribble Challenge Week 4',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Next Level Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_NEXT_LEVEL_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Week One',
    //               subTitle: '30 Day Ball Mastery Week 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Week Two',
    //               subTitle: '30 Day Ball Mastery Week 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Week Three',
    //               subTitle: '30 Day Ball Mastery Week 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Week Four',
    //               subTitle: '30 Day Ball Mastery Week 4',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'No More Weak Foot',
    //           subTitle: '',
    //           actionType: 'SET_NO_MORE_WEAK_FOOT_DATA',
    //           children: [
    //             {
    //               title: 'P1: Right Foot Only',
    //               subTitle: '30 Day No Weak Foot 01 Right',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Left Foot Only',
    //               subTitle: '30 Day No Weak Foot 02 Left Foot',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Both Feet',
    //               subTitle: '30 Day No Weak Foot 03 Both Feet',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Tight Space Sessions',
    //           subTitle: '',
    //           actionType: 'SET_TIGHT_SPACE_SESSIONS_DATA',
    //           children: [
    //             {
    //               title: 'P1: Week One',
    //               subTitle: '30 Day Tight Space Week 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Week Two',
    //               subTitle: '30 Day Tight Space Week 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Week Three',
    //               subTitle: '30 Day Tight Space Week 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Week Four',
    //               subTitle: '30 Day Tight Space Week 4',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Fitness & Ball Mastery',
    //           subTitle: '',
    //           actionType: 'SET_FITNESS_AND_BALL_MASTERY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Week One',
    //               subTitle: '30 Day Fitness Week 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Week Two',
    //               subTitle: '30 Day Fitness Week 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Week Three',
    //               subTitle: '30 Day Fitness Week 3',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Week Four',
    //               subTitle: '30 Day Fitness Week 4',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: '7 Day Skills Challenge',
    //       subTitle: '',
    //       actionType: 'SET_7_DAY_SKILLS_CHALLENGE_DATA',
    //       children: [
    //         {
    //           title: '7 Day Skills Challenge',
    //           subTitle: '',
    //           actionType: 'SET_7_DAY_SKILLS_CHALLENGE_CHILD_DATA',
    //           children: [
    //             {
    //               title: 'P1: 7 Day Skills Challenge Program',
    //               subTitle: 'Free Section (Free) 7-Day',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: `Beginner's Essentials`,
    //       subTitle: '',
    //       actionType: 'SET_BEGINNERS_ESSENTIALS_CATEGORY_DATA',
    //       children: [
    //         {
    //           title: `Beginner's Essentials`,
    //           subTitle: '',
    //           actionType: 'SET_BEGINNER_ESSENTIALS_SUBCATEGORY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Ball Mastery',
    //               subTitle: 'Ball Mastery Beginners',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Juggling',
    //               subTitle: 'Juggling Beginners',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Dribbling',
    //               subTitle: 'Dribbling Beginners',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Passing',
    //               subTitle: 'Passing Beginners',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },{
    //       title: '1v1 Games & Challenges',
    //       subTitle: '',
    //       actionType: 'SET_1V1_GAMES_AND_CHALLENDGES_CHILD_DATA',
    //       children: [
    //         {
    //           title: '1v1 Fun Games',
    //           subTitle: '',
    //           actionType: 'SET_SET_1V1_FUND_GAMES_DATA',
    //           children: [
    //             {
    //               title: 'P1: Keeper vs Striker 1v1',
    //               subTitle: '1V1 Finishing Games 1 part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Dribbling Chase & Finish',
    //               subTitle: '1V1 Finishing Games 2 part',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: More 1v1 Games',
    //               subTitle: '1V1%20Soccer%20Chasing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P4: Soccer Tagging',
    //               subTitle: 'Chasing Tagging Games Playing',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P5: Soccer Chasing',
    //               subTitle: 'Soccer Chasing Main',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P6: Soccer Racing Part 1',
    //               subTitle: 'Games Playing, Racing Part 1',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P7: Soccer Racing Part 2',
    //               subTitle: 'Games Playing, Racing Part 2',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P8: Play Practice Play',
    //               subTitle: 'Games Playing, Practising',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Skills Challenges',
    //           subTitle: '',
    //           actionType: 'SET_SKILL_CHALLENGES_DATA',
    //           children: [
    //             {
    //               title: 'P1: Ball Mastery Challenges',
    //               subTitle: 'Anytime Skills 01 Ball Mastery',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P2: Juggling Challenges',
    //               subTitle: 'Anytime Skills 02 Juggle Master',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //             {
    //               title: 'P3: Figure 8 Dribbling Challenges',
    //               subTitle: 'Anytime Skills 03 Figure 8',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //         {
    //           title: 'Skills Library',
    //           subTitle: '',
    //           actionType: 'SET_SKILLS_LIBRARY_DATA',
    //           children: [
    //             {
    //               title: 'P1: Sample Skills',
    //               subTitle: 'Summary Anytime skills',
    //               actionType: SubfolderPageUrl.videoSeries,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      title: 'Warm-Up and Quick Sessions',
      subTitle: '',
      actionType: 'SET_WARM_UP_AND_QUICK_SESSIONS_DATA',
      children: [
        {
          title: 'Dynamic Warmups',
          subTitle: '',
          actionType: 'SET_DYNAMIC_WARMUPS_DATA',
          children: [
            {
              title: 'Dynamic Warmups',
              subTitle: '',
              actionType: 'SET_DYNAMIC_WARMUPS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: Functional Movements, Dribbling & Juggling',
                  subTitle: 'Dynamic Warmup Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Functional Movements, Dribbling & Juggling',
                  subTitle: 'Dynamic Warmup Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Dribbling Warm-up',
                  subTitle: 'Dynamic Warmup Pro 03 Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Juggling Warm-up',
                  subTitle: 'Dynamic Warmup Pro 04 Juggling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Passing Warm-up',
                  subTitle: 'Dynamic Warmup Pro 05 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Quick Sessions',
          subTitle: '',
          actionType: 'SET_QUICK_SESSIONS_DATA',
          children: [
            {
              title: 'Quick Sessions',
              subTitle: '',
              actionType: 'SET_QUICK_SESSIONS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: Juggling Beginner',
                  subTitle: 'Choice of Juggle Master 01 Begin',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Juggling Intermediate',
                  subTitle: 'Choice of Juggle Master 02 Inter',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Juggling Advance',
                  subTitle: 'Choice of Juggle Master 03 Adv',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Ball Mastery',
                  subTitle: '(Intermed to Adv) Ball Mastery',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Dribbling',
                  subTitle: '(Interm to Adv) Dribbling Drills',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P6: Passing & Receiving',
                  subTitle: 'Choose Passing Wall & Partner',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P7: 1v1 Moves',
                  subTitle: 'Select 1v1 Moves',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P8: Finishing',
                  subTitle: 'Select Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Juggling and Ball Mastery',
      subTitle: '',
      actionType: 'SET_JUGGLING_AND_BALL_MASTERY_DATA',
      children: [
        {
          title: 'Juggling & Aerial Control',
          subTitle: '',
          actionType: 'SET_JUGGLING_AND_AERIAL_CONTROL_DATA',
          children: [
            {
              title: 'Juggling Warm-up',
              subTitle: '',
              actionType: 'SET_JUGGLING_WARMPUP_DATA',
              children: [
                {
                  title: 'P1: Juggling Warm-up',
                  subTitle: '(Aerial) Warm-up Juggle',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Thigh Juggling Beginner',
              subTitle: '',
              actionType: 'SET_THIGH_JUGGLING_BEGINNER_DATA',
              children: [
                {
                  title: 'P1: Thigh Juggling',
                  subTitle: '2 Footed Thigh Juggling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Thigh Juggling',
                  subTitle: '2 Footed Thigh Juggling 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Thigh Juggling',
                  subTitle: '2 Footed Thigh Juggling 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Kick & Catch Juggling Beginner',
              subTitle: '',
              actionType: 'SET_KICK_CATCH_JUGGLING_BEGINNER_DATA',
              children: [
                {
                  title: 'P1: Kick & Catch Juggling',
                  subTitle: 'Catch & Hit Juggle 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Kick & Catch Juggling',
                  subTitle: 'Catch & Hit Juggle 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Kick & Catch Juggling',
                  subTitle: 'Catch & Hit Juggle 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Kick & Catch Juggling',
                  subTitle: 'Catch & Hit Juggle 04 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Juggle Bounce Beginner',
              subTitle: '',
              actionType: 'SET_JUGGLE_BOUNCE_BEGINNER_DATA',
              children: [
                {
                  title: 'P1: Juggle Bounce',
                  subTitle: 'Beginner Juggle & Bounce 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Juggle Bounce',
                  subTitle: 'Beginner Juggle & Bounce 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Intermediate Juggling',
              subTitle: '',
              actionType: 'SET_INTERMEDIATE_JUGGLING_DATA',
              children: [
                {
                  title: 'P1: Juggle Bounce and Kick & Catch Juggling',
                  subTitle: '1 Intermed Juggle Master 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Continuous Juggle Bounce & Multiple Kick & Catch Juggling',
                  subTitle: '2 Intermed Juggle Master 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Continuous Juggling',
                  subTitle: '2 Intermed Juggle Master 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Advanced Juggling Part One',
              subTitle: '',
              actionType: 'SET_ADVANCED_JUGGLING_PART_ONE_DATA',
              children: [
                {
                  title: 'P1: Isolated Feet, Stalls, Bounce and Overhead Juggling',
                  subTitle: 'Advanced Juggling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Continuous, Seated, Isolated Feet and Inside/Outside Feet',
                  subTitle: 'Advanced Juggling 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Advanced Juggling Part Two',
              subTitle: '',
              actionType: 'SET_ADVANCED_JUGGLING_PART_TWO_DATA',
              children: [
                {
                  title: 'P1: Shoulder, Thigh, Hopping, Ladder, Figure 8 and More',
                  subTitle: 'Advanced Moving Juggling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Walking, Running, Flicks and More',
                  subTitle: 'Advanced Moving Juggling 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Advanced Juggling Part Three',
              subTitle: '',
              actionType: 'SET_ADVANCED_JUGGLING_PART_THREE_DATA',
              children: [
                {
                  title: 'P1: Low Low High, Circle, Figure 8 and More',
                  subTitle: 'Flicks | Juggling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Various Parts of Feet, Freestyle and More',
                  subTitle: 'Flicks | Juggling 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Tight Square, Body, Overhead and More',
                  subTitle: 'Flicks | Juggling 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Juggling With Different Sized Balls',
              subTitle: '',
              actionType: 'SET_JUGGLING_WITH_DIFFERENT_SIZED_BALLS_DATA',
              children: [
                {
                  title: 'P1: Size One Ball Juggling',
                  subTitle: 'Ball of Size One',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Tennis Ball Juggling',
                  subTitle: 'Big Tennis Ball Pro',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Mixed Ball Juggling',
                  subTitle: 'Balls of Mixed-size',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Partner Toss, Volley and Aerial Control',
              subTitle: '',
              actionType: 'SET_PARTNER_TOSS_VOLLEY_AND_AERIAL_CONTROL_DATA',
              children: [
                {
                  title: 'P1: Laces Volley & Half Volley',
                  subTitle: 'Aerial Toss Part 1 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Other Parts of Feet Volley & Half Volley',
                  subTitle: 'Aerial Toss Part 1 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Thigh and Feet Volley & Half Volley',
                  subTitle: 'Aerial Toss Part 2 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Chest and Feet Volley & Half Volley',
                  subTitle: 'Aerial Toss Part 2 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Volley & Half Volley with Movement',
                  subTitle: 'Aerial Toss Part 2 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Wall Juggling',
              subTitle: '',
              actionType: 'SET_WALL_JUGGLING_DATA',
              children: [
                {
                  title: 'P1: Wall Juggling Exercises',
                  subTitle: 'Aero Wall Control',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Partner Juggling',
              subTitle: '',
              actionType: 'SET_PARTNER_JUGGLING_DATA',
              children: [
                {
                  title: 'P1: Various Partner Juggling Exercises',
                  subTitle: 'Aero Juggling with Partner',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Body Control, Dribbling and Finishing with Juggling',
              subTitle: '',
              actionType: 'SET_BODY_CONTROL_DRIBBLING_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Chest, Thigh and Shoulder and More',
                  subTitle: 'Body Control Juggle Master',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Aerial Control and Dribbling',
                  subTitle: 'Aerial Control Dribbling Escape',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Aerial Control and Finishing',
                  subTitle: 'Aerial Control Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Juggling Timed Challenges',
              subTitle: '',
              actionType: 'SET_JUGGLING_TIMED_CHALLENGES_DATA',
              children: [
                {
                  title: 'P1: Size 4 and 5 Sized Ball Challenges',
                  subTitle: '(Timed) Juggle Challenge 01 Reg',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Size One Ball Challenges',
                  subTitle: '(Timed) Juggle Challenge 02 One',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Tennis Ball Challenges',
                  subTitle: '(Timed) Juggle Challenge 3Tennis',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Thigh Juggling Challenges',
                  subTitle: '(Timed) Juggle Challenge 4 Thigh',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Ball Mastery',
          subTitle: '',
          actionType: 'SET_BALL_MASTERY_DATA',
          children: [
            {
              title: '1,000 Touch Ball Mastery - Part One - One',
              subTitle: '',
              actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_ONE_ONE_DATA',
              children: [
                {
                  title: 'P1: Toe Taps, Tick Tocks, Stationary, Pause & Moving',
                  subTitle: '1000 Touch P1 Part 1 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Toe Taps, Tick Tocks, Sole Rolls with Movement',
                  subTitle: '1000 Touch P1 Part 1 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Tick Tocks, Body Feints, Inside/Outsides and More',
                  subTitle: '1000 Touch P1 Part 1 Part 03',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Tick Tocks, Toe Taps, Inside/Outside, L-Behinds and More',
                  subTitle: '1000 Touch P1 Part 1 Part 04',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: '1,000 Touch Ball Mastery - Part One - Two',
              subTitle: '',
              actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_ONE_TWO_DATA',
              children: [
                {
                  title: 'P1: Sole Moves, Dribbles, Stepovers, Pull Backs and More',
                  subTitle: '1000 Touch P1 Part 2 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Pull Push, Inside Outside, Scissors, Stepover and More',
                  subTitle: '1000 Touch P1 Part 2 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Scissors, Stepover, Sole, Hop Taps and More',
                  subTitle: '1000 Touch P1 Part 2 Part 03',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: '1,000 Touch Ball Mastery - Part One - Three',
              subTitle: '',
              actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_ONE_THREE_DATA',
              children: [
                {
                  title: 'P1: V-Pulls, Dribbles, Scissors, Brazilian Toe Taps and More',
                  subTitle: '1000 Touch P1 Part 3 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: V-Pulls, Scissors, Toe Drags, SoleFlicks and More',
                  subTitle: '1000 Touch P1 Part 3 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: '1,000 Touch Ball Mastery - Part Two - One',
              subTitle: '',
              actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_TWO_ONE_DATA',
              children: [
                {
                  title: 'P1: Chops, L-Behind,  Toe Pulls and More',
                  subTitle: '1000 Touch P2 Part 1 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: L-Behind, Outside/Inside, Cruyff, La Croqueta and More',
                  subTitle: '1000 Touch P2 Part 1 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: '1,000 Touch Ball Mastery - Part Two - Two',
              subTitle: '',
              actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_TWO_TWO_DATA',
              children: [
                {
                  title: 'P1: Sole Roll V-Pulls, Curls, Scissors, Stepover and More',
                  subTitle: '1000 Touch P2 Part 2 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Tick Tocks and Roll, Sole, Cruyff, Pull Push and More',
                  subTitle: '1000 Touch P2 Part 2 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: '1,000 Touch Ball Mastery - Part Two - Three',
              subTitle: '',
              actionType: 'SET_1000_TOUCH_BALL_MASTERY_PART_TWO_THREE_DATA',
              children: [
                {
                  title: 'P1: Pull Backs, Directional Control, L-Behinds and More',
                  subTitle: '1000 Touch P2 Part 3 Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Toe Taps, Tick Tocks, L-Behinds, Directional Control and More',
                  subTitle: '1000 Touch P2 Part 3 Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Double Scissors, L-Behinds, V-Pulls, Dribbling and More',
                  subTitle: '1000 Touch P2 Part 3 Part 03',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: V-Pulls, La Croquetas, Sole Rolls and More',
                  subTitle: 'P4: V-Pulls, La Croquetas, Sole Rolls',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Four Corner Tight Dribbling',
                  subTitle: 'P5: Four Corner Tight Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P6: 101 Ball Mastery Review',
                  subTitle: 'P6: 101 Ball Mastery Review',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'One Cone Ball Mastery',
              subTitle: '',
              actionType: 'SET_ONE_CONE_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Hop Taps, U Patterns & More',
                  subTitle: '1 Cone Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Hops Taps, Sole Flicks, V-Pulls',
                  subTitle: '1 Cone Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Fake Pass, L-Behind, Triangle Touch & More',
                  subTitle: '1 Cone Pro 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Maradona, La Croqueta, Lateral Dribbles & More',
                  subTitle: '1 Cone Pro 04 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Two Cone Ball Mastery Part One',
              subTitle: '',
              actionType: 'SET_TWO_CONE_BALL_MASTERY_PART_ONE_DATA',
              children: [
                {
                  title: 'P1: La Croqueta, Sole Roll, Outside/Inside L-Behind & More',
                  subTitle: 'Dual Cone Pro P1 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Sole Role, Inside/Outside L-Behind & More',
                  subTitle: 'Dual Cone Pro P1 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Two Cone Ball Mastery Part Two',
              subTitle: '',
              actionType: 'SET_TWO_CONE_BALL_MASTERY_PART_TWO_DATA',
              children: [
                {
                  title: 'P1: L-Behind, Toe Drag, Pull Back, V-Pull & More',
                  subTitle: 'Dual Cone Pro P2 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Pull Back, U-Patterns, V-Pull, Hop Tap & More',
                  subTitle: 'Dual Cone Pro P2 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Two Cone Ball Mastery Part Three',
              subTitle: '',
              actionType: 'SET_TWO_CONE_BALL_MASTERY_PART_THREE_DATA',
              children: [
                {
                  title: 'P1: Pull Backs, Triangle Touch, Sole Flicks, Pull Push & More',
                  subTitle: 'Dual Cone Pro P3 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: L-Behind, V-Pulls, Pull Backs, Single Feet & More',
                  subTitle: 'Dual Cone Pro P3 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Full Review of Select Two Cone Moves',
                  subTitle: 'Dual Cone Pro P3 03 Full Review',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Four Cone Essential Ball Mastery',
              subTitle: '',
              actionType: 'SET_FOUR_CONE_ESSENTIAL_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Pull Backs, V-Pulls, La Croqueta & More',
                  subTitle: 'Four Cone Essential 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Cruyffs, U-Patterns, Pull Backs, Toe Taps, V-Pulls & More',
                  subTitle: 'Four Cone Essential 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Three to Six Cone Ball Mastery',
              subTitle: '',
              actionType: 'SET_THREE_TO_SIX_CONE_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Three Cone Push Pull, Sole Pyramid, V-Pulls & More',
                  subTitle: 'Four Cone Pro P1 Three Cone',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Four Cone Hop Tap, Tick Tocks, Weaves & More',
                  subTitle: 'Four Cone Pro P2 Four Cone',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Six Cone Pull Backs, Directional Control, Spinning & More',
                  subTitle: 'Four Cone Pro P3 Six Cone',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: '101 Ball Mastery Drills',
          subTitle: '',
          actionType: 'SET_101_BALL_MASTERY_DRILLS_DATA',
          children: [
            {
              title: '101 Ball Mastery Drills',
              subTitle: '',
              actionType: 'SET_101_BALL_MASTERY_DRILLS_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: 101 Ball Mastery Part 1',
                  subTitle: 'P1: 101 Ball Mastery Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: 101 Ball Mastery Part 2',
                  subTitle: 'P2: 101 Ball Mastery Part 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: 101 Ball Mastery Part 3',
                  subTitle: 'P3: 101 Ball Mastery Part 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: 101 Ball Mastery Part 4',
                  subTitle: 'P4: 101 Ball Mastery Part 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: 101 Ball Mastery Part 5',
                  subTitle: 'P5: 101 Ball Mastery Part 5',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P6: 101 Ball Mastery Part 6',
                  subTitle: 'P6: 101 Ball Mastery Part 6',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'More Ball Mastery',
          subTitle: '',
          actionType: 'SET_MORE_BALL_MASTERY_DATA',
          children: [
            {
              title: 'Lateral Movement Ball Mastery - Part One',
              subTitle: '',
              actionType: 'SET_LATERAL_MOVEMENT_BALL_MASTERY_PART_ONE_DATA',
              children: [
                {
                  title: "P1: Pull Backs, Sole Rolls, U's, La Croqueta and more",
                  subTitle: '1 Lateral Movement 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Toe Taps, Tick Tocks, V-Pulls, Freestyle and more',
                  subTitle: '1 Lateral Movement 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Lateral Movement Ball Mastery - Part Two',
              subTitle: '',
              actionType: 'SET_LATERAL_MOVEMENT_BALL_MASTERY_PART_TWO_DATA',
              children: [
                {
                  title: 'P1: Dribbling Combinations, V-Pulls, Turns and more',
                  subTitle: '2 Lateral Movement 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Dribbling Combinations, Scissors, Cruyffs, and more',
                  subTitle: '2 Lateral Movement 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Lateral Movement Ball Mastery - Part Three',
              subTitle: '',
              actionType: 'SET_LATERAL_MOVEMENT_BALL_MASTERY_PART_THREE_DATA',
              children: [
                {
                  title: 'P1: Dribbling, Juggling, V-Pulls and more',
                  subTitle: '3 Lateral Movement 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Stanley Matthews, L-Behind, Sole Rolls and more',
                  subTitle: '3 Lateral Movement 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Dynamic Ball Mastery',
              subTitle: '',
              actionType: 'SET_DYNAMIC_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Sole Rolls, Inside/Outsides, Toe Taps and more',
                  subTitle: 'Dynamic Ball Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Tick Tocks, Elastico, Stepover, Scissors and more',
                  subTitle: 'Dynamic Ball Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Tick Tocks, Stanley Matthews, Pull Push and more',
                  subTitle: 'Dynamic Ball Pro 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Midfielder Ball Mastery',
              subTitle: '',
              actionType: 'SET_MIDFIELDER_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: V-Pulls, Directional Control, Pull Backs and more.',
                  subTitle: '(Midfield Ball Mastery) 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Large V-Pulls, Directional Control, L-Behinds and more',
                  subTitle: '(Midfield Ball Mastery) 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Tick Tocks, Dribbling, L-Behinds, Freesytle and more',
                  subTitle: '(Midfield Ball Mastery) 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Ball Mastery & Dribbling',
              subTitle: '',
              actionType: 'SET_BALL_MASTERY_AND_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Diagonal Dribbling, Scissors, Turns, Cuts and more',
                  subTitle: 'Ball Mastery & Dribbling Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Diagonal Dribbling, V-Pull, Freestyle, Maradonna and more',
                  subTitle: 'Ball Mastery & Dribbling Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Sole Roll, Tick Tocks, Freestyle, Stepover and more',
                  subTitle: 'Ball Mastery & Dribbling Part 03',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Ball Mastery & Tennis Ball',
              subTitle: '',
              actionType: 'SET_BALL_MASTERY_AND_TENNIS_BALL_DATA',
              children: [
                {
                  title: 'Toe Taps, Tick Tocks, Inside/Outside & More',
                  subTitle: 'Ball Mastery & Tennis Ball',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Dribbling & Turns',
      subTitle: '',
      actionType: 'SET_DRIBBLING_AND_TURNS_DATA',
      children: [
        {
          title: 'Tight Space Dribbling',
          subTitle: '',
          actionType: 'SET_TIGHT_SPACE_DRIBBLING_DATA',
          children: [
            {
              title: 'Figure 8 Dribbling & Ball Mastery',
              subTitle: '',
              actionType: 'SET_FIGURE_EIGHT_DRIBBLING_AND_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Figure 8 Close Control',
                  subTitle: 'Figure 8 Close Control 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Figure 8 Close Control',
                  subTitle: 'Figure%208%20Close%20Control%20%2002%20Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Figure 8 Essentials',
                  subTitle: 'Figure 8 Essential Drill 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Figure 8 Longer Distance',
                  subTitle: 'Figure 8 Long Distance Drills',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Lateral Figure 8 Drills',
                  subTitle: 'Figure 8 Lateral Drills 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P6: Figure 8 1v1 Moves',
                  subTitle: 'Figure 8 1v1 Moves 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P7: Figure 8 & Ball Mastery',
                  subTitle: 'Figure 8 Ball Mastery 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Tight Dribbling',
              subTitle: '',
              actionType: 'SET_TIGHT_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Three, Four & Tight Dribbling',
                  subTitle: 'Fast Speed Tight Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Tight Box Dribbling',
              subTitle: '',
              actionType: 'SET_TIGHT_BOX_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Tight Box Dribbling - Weaves, Sole Rolls and Turns',
                  subTitle: 'Box Dribbling Pro Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Tight Box Dribbling - Freestyle & Turns',
                  subTitle: 'Box Dribbling Pro Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Five Star Tight Dribbling',
              subTitle: '',
              actionType: 'SET_FIVE_STAR_TIGHT_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Five Star Dribbling - Weaves, Sole Rolls and more',
                  subTitle: 'Five Star Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Body Feints & Dribbling',
              subTitle: '',
              actionType: 'SET_BODY_FEINTS_AND_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Feints, Sole Rolls, Dribbling & more',
                  subTitle: 'Body Feint Pro 01 Body Feint',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Feints, Chops, Figure 8 & more',
                  subTitle: 'Body Feint Pro 02 Body Feint',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Feints, Freestyle & Dribbling',
                  subTitle: 'Body Feint Pro 03 Body Feint',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Three Cone Dribbling',
              subTitle: '',
              actionType: 'SET_THREE_CONDE_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Dribbling, One and Two Touch Weaves',
                  subTitle: '3 Cone Dribbling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Dribbling, 360 Turns, La Croqueta and more',
                  subTitle: '3 Cone Dribbling 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Freestyle Dribbling & Change Direction',
              subTitle: '',
              actionType: 'SET_FREESTYLE_DRIBBLING_AND_CHANGE_DIRECTION_DATA',
              children: [
                {
                  title: 'P1: Tight Box Freestyle, Cuts & More',
                  subTitle: 'Change Route & Freestyle Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Dribbling, 360 Turns, La Croqueta and more',
                  subTitle: 'P2: Tight Box Freestyle, Scissors, Stepovers & More',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Ronaldinho Freestyle Dribbling',
          subTitle: '',
          actionType: 'SET_RONALDINHO_DRIBBLING_DATA',
          children: [
            {
              title: 'Ronaldinho Freestyle Dribbling',
              subTitle: '',
              actionType: 'SET_RONALDINHO_DRIBBLING_CHILDREN_DATA',
              children: [
                {
                  title: 'P1: Ronaldinho Freestyle Dribbling',
                  subTitle: 'P1: Ronaldinho Free Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Dribbling & Change Direction',
          subTitle: '',
          actionType: 'SET_DRIBBLING_AND_CHANGE_DIRECTION_DATA',
          children: [
            {
              title: 'Dribbling & Change Direction Part One & Two',
              subTitle: '',
              actionType: 'SET_DRIBBLING_AND_CHANGE_DIRECTION_PART_ONE_AND_TWO_DATA',
              children: [
                {
                  title: 'P1: Inside/Outside, Feints, L-Beind Scissors & more',
                  subTitle: 'Fast Speed Dribble Master P1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Lateral Dribbling, Speed Dribbling, Hops & more',
                  subTitle: 'Fast Speed Dribble Master P2 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Dribbling & Change Direction Part Three & Four',
              subTitle: '',
              actionType: 'SET_DRIBBLING_AND_CHANGE_DIRECTION_PART_THREE_AND_FOUR_DATA',
              children: [
                {
                  title: 'P1: Sole Rolls and Outside Foot Change Direction',
                  subTitle: 'Fast Speed Dribble Master P3 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Multiple Sole Rolls and Outside Foot Change Direction',
                  subTitle: 'Fast Speed Dribble Master P3 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Diagonal Dribbling, Tick Tocks, L-Behinds & more',
                  subTitle: 'Fast Speed Dribble Master P4 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Diagonal Dribbling, Cuts, Hps, L-Behinds & more',
                  subTitle: 'Fast Speed Dribble Master P4 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Line Cone & Cone Slalom',
          subTitle: '',
          actionType: 'SET_LINE_CONE_AND_CONE_SLALOM_DATA',
          children: [
            {
              title: 'Line Cone Dribbling Part One & Two',
              subTitle: '',
              actionType: 'SET_LINE_CONE_DRIBBLING_PART_ONE_AND_TWO_DATA',
              children: [
                {
                  title: 'P1: Speed Dribbling, Tight Control, Weave and more',
                  subTitle: '1 Line Cone 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Weaves, Stops, Sole Rolls, V-Pulls and more',
                  subTitle: '1 Line Cone 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Taps, Rolls, La Croqueta, V-Pulls and more',
                  subTitle: '2 Line Cone 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Taps Backward, Weaves, V-Pull Weaves and more',
                  subTitle: '2 Line Cone 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Line Cone Dribbling Part Three & Four',
              subTitle: '',
              actionType: 'SET_LINE_CONE_DRIBBLING_PART_THREE_AND_FOUR_DATA',
              children: [
                {
                  title: 'P1: Tap Weaves, Outside and Inside Foot Dribbling and more',
                  subTitle: '3 Line Cone 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Two Touch Weaves, Single Foot Rolls and more',
                  subTitle: '3 Line Cone 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Sole Rolls, Speed Dribbling, Cuts and more',
                  subTitle: '4 Line Cone 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Speed Dribbling, Turns, Hops and more',
                  subTitle: '4 Line Cone 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Cone Weave Slalom',
              subTitle: '',
              actionType: 'SET_CONE_WEAVE_SLALOM_DATA',
              children: [
                {
                  title: 'P1: Single Foot Weaves, Sole Roll, 360 Turns and more',
                  subTitle: 'Cone Slalom Pro 01 Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Line Cone and Slalom Combination',
                  subTitle: 'Cone Slalom Pro 02 Line & Slalom',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Feints, Stops, V-Pulls and more',
                  subTitle: 'Cone Slalom Pro 03 Part 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Sole Rolls, V-Pulls, Weaves and more',
                  subTitle: 'Cone Slalom Pro 04 Part 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: L-Behinds, Ineista, Stepover, Maradona and more',
                  subTitle: 'Cone Slalom Pro 05 Part 5',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P6: Stanley Matthews, Passing, Sole Rolls and more',
                  subTitle: 'Cone Slalom Pro 06 Part 6',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Line Cone & Slalom Dribbling',
              subTitle: '',
              actionType: 'SET_LINE_CONE_AND_SLALOM_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: One and Two Touch Weaves and more',
                  subTitle: 'Cone Line Slalom 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Single Foot Dribbling, Cuts, Inside Turns and more',
                  subTitle: 'Cone Line Slalom 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Rectangle Weaves, Turns, Speed Dribbling and more',
                  subTitle: 'Cone Line Slalom 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Mixed Distances Line Cone Dribbling',
              subTitle: '',
              actionType: 'SET_MIXED_DISTANCES_LINE_CONE_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Snake Lane Dribbling, Sole Rolls, Weaves and more',
                  subTitle: 'Cone Line Mixed Distance',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Speed, Obstacle & Lane Dribbling',
          subTitle: '',
          actionType: 'SET_SPEED_OBSTACLE_AND_LANE_DRIBBLING_DATA',
          children: [
            {
              title: 'Speed Dribbling',
              subTitle: '',
              actionType: 'SET_SPEED_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Speed Dribbling & Turns',
                  subTitle: 'Fast Dribbling & Turns',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Speed Dribbling & Weaves',
                  subTitle: 'Fast Dribbling & Weaves',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Speed Dribbling & Shuttle Run',
                  subTitle: 'Fast Dribbling & Fitness',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Speed Dribbling & Hopping',
                  subTitle: 'Fast Dribbling Hop Taps | Other',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Obstacle and Lane Dribbling',
              subTitle: '',
              actionType: 'SET_OBSTACLE_AND_LANE_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Obstacle Course Dribbling',
                  subTitle: 'Course of Obstacle Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Lateral Lane Dribbling',
                  subTitle: 'Fast Speed 07 Lateral Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Snake Lane Dribbling',
                  subTitle: 'Fast Speed 08 Lane Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Shape Pattern Dribbling',
          subTitle: '',
          actionType: 'SET_SHAPE_PATTERN_DRIBBLING_DATA',
          children: [
            {
              title: 'Box Dribbling',
              subTitle: '',
              actionType: 'SET_BOX_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Foundation Box - Turns, Sole Roll, Dribbling and more',
                  subTitle: 'Foundation Box Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: X Box - Figure 8, Weave, Speed Dribbling and more',
                  subTitle: '(X)Box Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Close Box - Dribble Weave, Speed Dribbling and more',
                  subTitle: 'Close Box Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: 1v1 Box Moves - 1v1 Moves around Box and more',
                  subTitle: '1v1 Moves & More',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Five Star Dribbling',
              subTitle: '',
              actionType: 'SET_FIVE_STAR_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Tight Dribbling, Sole Roll, La Croqueta and more',
                  subTitle: 'Five Star Pro 01 One to Two Feet',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Speed Dribbling, Weave, Inside/Outside Foot and more',
                  subTitle: 'Five Star Pro 02 Five to 10 Feet',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Mix Distances Five Star Weaves and more',
                  subTitle: 'Five Star Pro 03 Mixed Distances',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Dribble and Sprint Combinations',
                  subTitle: 'Five Star Pro 04 Dribble+Sprint',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Full Review Five Star',
                  subTitle: 'Five Star Pro 05 Full Review',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'More Shape Pattern Dribbling',
              subTitle: '',
              actionType: 'SET_MORE_SHAPE_PATTERN_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: T-Formation Dribbling',
                  subTitle: 'Fast Speed 04 Dribble T-Form',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Circle Dribbling',
                  subTitle: 'Fast Speed 06 Circle Dribbling',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Pentagon Dribbling',
                  subTitle: 'Dribble Pentagon',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: W-Pattern Dribbling',
                  subTitle: '(W)Pattern',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Freestyle, 1v1 & Shielding',
          subTitle: '',
          actionType: 'SET_FREESTYLE_1V1_AND_SHIELDING_DATA',
          children: [
            {
              title: 'Freestyle Dribbling',
              subTitle: '',
              actionType: 'SET_FREESTYLE_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Freesytle Dribbling',
                  subTitle: 'Brief Freestyle 01 Free',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Freesytle Dribbling',
                  subTitle: 'Brief Freestyle 02 Free',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Circle 1v1, Tag & Shielding',
              subTitle: '',
              actionType: 'SET_CIRCLE_1V1_TAG_AND_SHIELDING_DATA',
              children: [
                {
                  title: 'P1: Circle Dribbling, Tag & 1v1',
                  subTitle: 'Circle Dribbling Tag & 1v1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Dribbling & Shielding',
                  subTitle: 'Dribbling & Shielding',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Turns & Escaping',
      subTitle: '',
      actionType: 'SET_SET_TURNS_AND_ESCAPING_DATA',
      children: [
        {
          title: 'Quick Turns & Escape',
          subTitle: '',
          actionType: 'SET_QUICK_TURNS_AND_ESCAPE_DATA',
          children: [
            {
              title: 'Quick Turns & Change Direction',
              subTitle: '',
              actionType: 'SET_QUICK_TURNS_AND_CHANGE_DIRECTION_DATA',
              children: [
                {
                  title: 'P1: Inside, Outside, Cruyff, L-Behind & more',
                  subTitle: 'Change Direction P1 01 Turns P1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Stepover, Scissors, Roulette, Tick Tock & more',
                  subTitle: 'Change%20Direction%20P1%20%2002%20Turns%20P2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Speed Dribbling & Foundational Turns',
                  subTitle: 'Change Direction P2 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Full Review of Essential Turns',
                  subTitle: 'Change Direction P2 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Turning & Escaping Pressure ',
              subTitle: '',
              actionType: 'SET_TURNING_AND_ESCAPING_PRESSURE_DATA',
              children: [
                {
                  title: 'P1: Freesytle Dribbling, Stepover, Spinning and more',
                  subTitle: 'Escape Pressure Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: L-Behind, Scissors, Stepovers and more',
                  subTitle: 'Escape Pressure Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Short & Double Turns',
          subTitle: '',
          actionType: 'SET_SHORT_AND_DOUBLE_TURNS_DATA',
          children: [
            {
              title: 'Line Cone & Short/Long Turns',
              subTitle: '',
              actionType: 'SET_TURNING_AND_ESCAPING_PRESSURE_DATA',
              children: [
                {
                  title: 'P1: Line Cone - Foundational Turns',
                  subTitle: 'Cone Line & Turns',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Short & Long Turns',
                  subTitle: '(Short Long) Turns',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Double Turns',
              subTitle: '',
              actionType: 'SET_DOUBLE_TURNS_DATA',
              children: [
                {
                  title: 'P1: Inside, Outside, Single, Both Feet and more',
                  subTitle: 'Double Turns 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Feints, Outside, Rolls, Pull Backs and more',
                  subTitle: 'Double Turns 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Curl Spin, Pull Backs, Cruyff and more',
                  subTitle: 'Double Turns 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: L-Behind, Cruyff, Curl Spin, L-Behind and more',
                  subTitle: 'Double Turns 04 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Passing, Wall & Receiving',
      subTitle: '',
      actionType: 'SET_PASSING_WALL_AND_RECEIVING_DATA',
      children: [
        {
          title: 'Wall Passing',
          subTitle: '',
          actionType: 'SET_WALL_PASSING_DATA',
          children: [
            {
              title: 'First Touch Rebounder Part 1',
              subTitle: '',
              actionType: 'SET_FIRST_TOUCH_REBOUNDER_PART_1_DATA',
              children: [
                {
                  title: 'P1: One & Two Touches, Inside/Outside Foot & more',
                  subTitle: 'First Touch Pro P1 01 Part One',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Two Touches, Laces, Inside/Outside & more',
                  subTitle: 'First Touch Pro P1 02 Part Two',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'First Touch Rebounder Part 2',
              subTitle: '',
              actionType: 'SET_FIRST_TOUCH_REBOUNDER_PART_2_DATA',
              children: [
                {
                  title: 'P1: Hopping, Sole Roll, Passing & more',
                  subTitle: 'First Touch Pro P2 01 Part One',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Sole Roll, One Cone, L-Behind & more',
                  subTitle: 'First Touch Pro P2 02 Part Two',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'First Touch Rebounder Part 3',
              subTitle: '',
              actionType: 'SET_FIRST_TOUCH_REBOUNDER_PART_3_DATA',
              children: [
                {
                  title: 'P1: Cruyff, Step Over, Scissors & more',
                  subTitle: 'First Touch Pro P3 01 Part One',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Aerial Control off Wall',
                  subTitle: 'First Touch Pro P3 02 Aerial',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: ' Core Passing',
          subTitle: '',
          actionType: 'SET_CORE_PASSING_DATA',
          children: [
            {
              title: 'Foundation Passing',
              subTitle: '',
              actionType: 'SET_FOUNDATION_PASSING_DATA',
              children: [
                {
                  title: 'P1: Foundation Passing Series',
                  subTitle: 'Foundation Passing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'One Cone Passing',
              subTitle: '',
              actionType: 'SET_ONE_CONE_PASSING_DATA',
              children: [
                {
                  title: 'P1: One Cone Passing Part 1',
                  subTitle: '1 Cone Passing 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: One Cone Passing Part 2',
                  subTitle: '1 Cone Passing 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Partner Passing Part One',
              subTitle: '',
              actionType: 'SET_PARTNER_PASSING_PART_ONE_DATA',
              children: [
                {
                  title: 'P1: One & Two Touch, Dead Stop & more',
                  subTitle: '2 Touch Passing Partner 01 Pass',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: One & Two Touches, Traps & more',
                  subTitle: '2 Touch Passing Partner 02 Pass',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Partner Passing Part Two',
              subTitle: '',
              actionType: 'SET_PARTNER_PASSING_PART_TWO_DATA',
              children: [
                {
                  title: 'P1: Directional Control, Inside/Outside & more',
                  subTitle: 'Directional Ctrl Sole 01 Pass',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Sole Rolls, Cone Shuffles & more',
                  subTitle: 'Directional Ctrl Sole 02 Pass',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Partner Passing Part Three',
              subTitle: '',
              actionType: 'SET_PARTNER_PASSING_PART_THREE_DATA',
              children: [
                {
                  title: 'P1: Full Review Passing Drills Part 1',
                  subTitle: '1 Cone Movement More 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Full Review Passing Drills Part 2',
                  subTitle: '1 Cone Movement More 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Passing & Moving',
              subTitle: '',
              actionType: 'SET_PASSING_AND_MOVING_DATA',
              children: [
                {
                  title: 'P1: Passing & Moving Drills',
                  subTitle: 'Area Running',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Passing Warmup',
                  subTitle: 'Dynamic Warmup Pro 05 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Passing Games',
                  subTitle: 'Passing Games',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Cone & Lateral Pass',
          subTitle: '',
          actionType: 'SET_CONE_AND_LATERAL_PASS_DATA',
          children: [
            {
              title: 'Lateral Line Cone Passing',
              subTitle: '',
              actionType: 'SET_LATERAL_LINE_CONE_PASSING_DATA',
              children: [
                {
                  title: 'P1: Lateral Line Cone Passing Series',
                  subTitle: 'Cone Line Lateral',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Ladder Passing',
              subTitle: '',
              actionType: 'SET_LADDER_PASSING_DATA',
              children: [
                {
                  title: 'P1: Ladder Passing Series',
                  subTitle: 'Footway Passing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Line Cone Passing',
              subTitle: '',
              actionType: 'SET_LINE_CONE_PASSING_DATA',
              children: [
                {
                  title: 'P1: Line Cone Passing Part 1',
                  subTitle: 'Cone Line Passing 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Line Cone Passing Part 2',
                  subTitle: 'Cone Line Passing 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Long Passing & Other',
          subTitle: '',
          actionType: 'SET_LONG_PASSING_AND_OTHER_DATA',
          children: [
            {
              title: 'Long Distance Passing',
              subTitle: '',
              actionType: 'SET_LONG_DISTANCE_PASSING_DATA',
              children: [
                {
                  title: 'P1: Low Driven Ball Passing',
                  subTitle: 'Distant Passing 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Passing & Sprinting',
              subTitle: '',
              actionType: 'SET_PASSING_AND_SPRINTING_DATA',
              children: [
                {
                  title: 'P1: Passing & Sprinting Series',
                  subTitle: 'Cardio Passing Drills',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'T-Formation Passing',
              subTitle: '',
              actionType: 'SET_T_FORMATION_PASSING_DATA',
              children: [
                {
                  title: 'P1: T-Formation Passing Part 1',
                  subTitle: '(T)Formation Passing Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P1: T-Formation Passing Part 2',
                  subTitle: '(T)Formation Passing Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Agility Rings Passing',
              subTitle: '',
              actionType: 'SET_AGILITY_RINGS_PASSING_DATA',
              children: [
                {
                  title: 'P1: Agility Rings Passing Part 1',
                  subTitle: 'Agility Ring Passing Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P1: Agility Rings Passing Part 2',
                  subTitle: 'Agility Ring Passing Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Circle Passing',
              subTitle: '',
              actionType: 'SET_CIRCLE_PASSING_DATA',
              children: [
                {
                  title: 'P1: Circle Passing Series',
                  subTitle: 'Circle Passing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '1v1 Moves & Dribbling',
      subTitle: '',
      actionType: 'SET_1V1_MOVES_AND_DRIBBLING_DATA',
      children: [
        {
          title: 'Five Star & Figure 8 1v1',
          subTitle: '',
          actionType: 'SET_FIVE_STAR_AND_FIGURE_8_1V1_DATA',
          children: [
            {
              title: 'Five Star 1v1 Moves - Part One',
              subTitle: '',
              actionType: 'SET_FIVE_STAR_1V1_MOVES_PART_ONE_DATA',
              children: [
                {
                  title: 'P1: Body Feints, Pull Backs, Scissors & more',
                  subTitle: '1v1 Moves Pro P1 01 1v1 Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Double Scissors, Stepover, Ronaldo Chops & more',
                  subTitle: '1v1 Moves Pro P1 02 1v1 Part 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Five Star 1v1 Moves - Part Two',
              subTitle: '',
              actionType: 'SET_FIVE_STAR_1V1_MOVES_PART_TWO_DATA',
              children: [
                {
                  title: 'P1: Iniesta, Turns, Stepovers, Scissors & more',
                  subTitle: '1v1 Moves Pro P2 01 1v1 Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Maradona, Turns, Ronaldo Chops & more',
                  subTitle: '1v1 Moves Pro P2 02 1v1 Part 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Five Star 1v1 Moves - Part Three',
              subTitle: '',
              actionType: 'SET_FIVE_STAR_1V1_MOVES_PART_THREE_DATA',
              children: [
                {
                  title: 'P1: L-Behinds, Sole Rolls, Scissors & more',
                  subTitle: '1v1 Moves Pro P3 01 1v1 Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Full Review of Key Moves',
                  subTitle: '1v1 Moves Pro P3 02 1v1 Part 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Figure 8 1v1 Moves',
              subTitle: '',
              actionType: 'SET_FIVE_STAR_1V1_MOVES_DATA',
              children: [
                {
                  title: 'P1: Sole Rolls, La Croqueta, Ronaldo Chops & more',
                  subTitle: 'Figure 8 Moves 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Speed Dribbling, Chops, Turns & more',
                  subTitle: 'Figure 8 Moves 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Clinical Finishing',
      subTitle: '',
      actionType: 'SET_CLINICAL_FINISHING_DATA',
      children: [
        {
          title: 'Rapid Fire & Directional Control',
          subTitle: '',
          actionType: 'SET_RAPID_FIRE_AND_DIRECTIONAL_CONTROL_DATA',
          children: [
            {
              title: 'Rapid Fire',
              subTitle: '',
              actionType: 'SET_RAPID_FIRE_DATA',
              children: [
                {
                  title: 'P1: Quick Shots, Back Heel, Volleys & more',
                  subTitle: 'Fire Rapid Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Stationary Balls, Sprinting & more',
                  subTitle: 'Fire Rapid Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Half Circle, Aerial Control & more',
                  subTitle: 'Fire Rapid Pro 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Directional Control - Back Towards Goal',
              subTitle: '',
              actionType: 'SET_DIRECTIONAL_CONTROL_BACK_TOWARDS_GOAL_DATA',
              children: [
                {
                  title: 'P1: Body Feints, Inside, Outside Foot Turns & more',
                  subTitle: 'Directional Control 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Body Feints, Inside, Outside Foot Stepovers & more',
                  subTitle: 'Directional Control 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Dribbling, Directional Control & Finish',
              subTitle: '',
              actionType: 'SET_DIRECTIONAL_CONTROL_AND_FINISH_DATA',
              children: [
                {
                  title: 'P1: Dribbling in Space, Directional Control & Finish',
                  subTitle: 'Directional Control 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: '1v1 & Dribbling',
          subTitle: '',
          actionType: 'SET_1V1_AND_DRIBBLING_DATA',
          children: [
            {
              title: '1v1 Moves & Finishing',
              subTitle: '',
              actionType: 'SET_1V1_MOVES_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Body Feints, Scissors, Stepovers & more',
                  subTitle: '1vs1 Moves & Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Attacking Winger Finishing',
              subTitle: '',
              actionType: 'SET_ATTACKING_WINGER_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Scissors, Stepovers, Body Feints & more',
                  subTitle: 'Attacking Winger',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Line Cone Dribbling',
              subTitle: '',
              actionType: 'SET_LINE_CONE_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Line Cone Dribbling Weaves & Finishing',
                  subTitle: 'Cone Line Dribbling Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Freestyle Dribbling & Finishing',
              subTitle: '',
              actionType: 'SET_FREESTYLE_DRIBBLING_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Aerial Control, Maze Dribbling & Finishing',
                  subTitle: 'Freestyle Dribbling Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: '1v1 Duels & Finishing',
              subTitle: '',
              actionType: 'SET_1V1_DUELS_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Keeper vs Striker 1v1 Duel',
                  subTitle: 'Battle Finishing Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Race to the Ball 1v1 Duel',
                  subTitle: 'Battle Finishing Part 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Lateral 1v1 & Finishing Duel',
                  subTitle: 'Battle Finishing Part 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Dribble, Pass & Shoot',
          subTitle: '',
          actionType: 'SET_DRIBBLE_PASS_AND_SHOOT_DATA',
          children: [
            {
              title: 'Passing & Finishing',
              subTitle: '',
              actionType: 'SET_PASSING_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Partner Passing Warmup & Finishing',
                  subTitle: 'Passing Warmup Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Partner Passing & Finishing',
                  subTitle: 'Pass & Shoot Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Cone Slalom Dribbling & Finishing',
              subTitle: '',
              actionType: 'SET_CONE_SLALOM_DRIBBLING_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Cone Slalom Dribbling, Sprinting & Finish',
                  subTitle: 'Cone Slalom Dribbling 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Cone Slalom Dribbling, Control & Finish',
                  subTitle: 'Cone Slalom Dribbling 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Line Cone Dribbling & Finishing',
              subTitle: '',
              actionType: 'SET_LINE_CONE_DRIBBLING_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Line Cone Dribbling Weaves & Finishing',
                  subTitle: 'Cone Line Dribbling Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Line Cone Dribble Chase',
              subTitle: '',
              actionType: 'SET_LINE_CONE_DRIBBLE_CHASE_DATA',
              children: [
                {
                  title: 'P1: Line Cone Dribble Chase Series',
                  subTitle: 'Cone Chase Dribbling Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: 'Aerial & Other',
          subTitle: '',
          actionType: 'SET_AERIAL_AND_OTHER_DATA',
          children: [
            {
              title: 'Aerial Control & Finishing',
              subTitle: '',
              actionType: 'SET_AERIAL_CONTROL_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Juggling Turn & Finish',
                  subTitle: 'Aerial Control Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Aerial Control, 1v1 & Finishing',
                  subTitle: 'Aerial Control, 1v1 Finishing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Crossing and Finishing',
              subTitle: '',
              actionType: 'SET_CROSSING_AND_FINISHING_DATA',
              children: [
                {
                  title: 'P1: Long Wide Passes & Finish - Part 1',
                  subTitle: 'Crossing and Passing 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Long Wide Passes & Finish - Part 2',
                  subTitle: 'Crossing and Passing 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Penalty Shootout',
              subTitle: '',
              actionType: 'SET_PENALTY_SHOOTOUT_DATA',
              children: [
                {
                  title: 'P1: Penalty Shooting Using Both Feet',
                  subTitle: 'Free Kick Penalty Shootout',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Fitness & Agility',
      subTitle: '',
      actionType: 'SET_FITNESS_AND_AGILITY_DATA',
      children: [
        {
          title: 'Ladders, Ball Mastery & Cardio',
          subTitle: '',
          actionType: 'SET_LADDERS_BALL_MASTERY_AND_CARDIO_DATA',
          children: [
            {
              title: 'Soccer Fitness, Ladders & Ball Mastery',
              subTitle: '',
              actionType: 'SET_SOCCER_FITNESS_LADDERS_AND_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Soccer Fitness Part 1',
                  subTitle: 'Endurance in Soccer Part 01',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Soccer Fitness Part 2',
                  subTitle: 'Endurance in Soccer Part 02',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Soccer Fitness Part 3',
                  subTitle: 'Endurance in Soccer Part 03',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Soccer Fitness Part 4',
                  subTitle: 'Endurance in Soccer Part 04',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Agility Ladders & Ball Mastery',
              subTitle: '',
              actionType: 'SET_AGILITY_LADDERS_AND_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Agility Ladders & Ball Mastery Part 1',
                  subTitle: 'Agility Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Agility Ladders & Ball Mastery Part 2',
                  subTitle: 'Agility Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Agility Ladders & Ball Mastery Part 3',
                  subTitle: 'Agility Pro 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Jump Rope & Aerial Control',
              subTitle: '',
              actionType: 'SET_JUMP_ROPE_AND_AERIAL_CONTROL_DATA',
              children: [
                {
                  title: 'P1: Jump Rope Series',
                  subTitle: 'Cable Jump Master 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Agility Rings Sessions',
              subTitle: '',
              actionType: 'SET_AGILITY_RINGS_SESSIONS_DATA',
              children: [
                {
                  title: 'P1: Agility Rings Sessions Part 1',
                  subTitle: 'Agility Rings Pro 01 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Agility Rings Sessions Part 2',
                  subTitle: 'Agility Rings Pro 02 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Agility Rings Sessions Part 3',
                  subTitle: 'Agility Rings Pro 03 Part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Hurdles & Dribbling',
              subTitle: '',
              actionType: 'SET_HURDLES_AND_DRIBBLING_DATA',
              children: [
                {
                  title: 'P1: Hurdles and Speed Dribbling',
                  subTitle: 'Fitness Hurdles Pro',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '30 Day Plans',
      subTitle: '',
      actionType: 'SET_THIRTY_DAY_PLANS_DATA',
      children: [
        {
          title: 'Get Started & Next Level Plans',
          subTitle: '',
          actionType: 'SET_GET_STARTED_AND_NEXT_LEVEL_PLANS_DATA',
          children: [
            {
              title: 'Foundational Skills: Ball Mastery, Juggling, Passing',
              subTitle: '',
              actionType: 'SET_FOUNDATIONAL_SKILLS_BALL_MASTERY_JUGGLING_PASSING_DATA',
              children: [
                {
                  title: 'P1: Week One',
                  subTitle: '30 Day Player Week 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Week Two',
                  subTitle: '30 Day Player Week 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Week Three',
                  subTitle: '30 Day Player Week 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Week Four',
                  subTitle: '30 Day Player Week 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Juggle Master',
              subTitle: '',
              actionType: 'SET_JUGGLE_MASTER_DATA',
              children: [
                {
                  title: 'P1: 30-Day Beginner',
                  subTitle: '(Start) Juggle Challenge 01 Beg',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: 30-Day Intermediate',
                  subTitle: '(Start) Juggle Challenge 02 Int',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: 30-Day Advanced',
                  subTitle: '(Start) Juggle Challenge 03 Adv',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Rebounder & Wall Passing',
              subTitle: '',
              actionType: 'SET_REBOUNDER_AND_WALL_PASSING_DATA',
              children: [
                {
                  title: 'P1: Week One',
                  subTitle: '(Start) Wall Pass Pro 1 Week',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Week Two',
                  subTitle: '(Start) Wall Pass Pro 2 Week',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Week Three',
                  subTitle: '(Start) Wall Pass Pro 3 Week',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Week Four',
                  subTitle: '(Start) Wall Pass Pro 4 Week',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Dribble Master',
              subTitle: '',
              actionType: 'SET_DRIBBLE_MASTER_DATA',
              children: [
                {
                  title: 'P1: Week One',
                  subTitle: 'Dribble Challenge Week 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Week Two',
                  subTitle: 'Dribble Challenge Week 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Week Three',
                  subTitle: 'Dribble Challenge Week 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Week Four',
                  subTitle: 'Dribble Challenge Week 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Next Level Ball Mastery',
              subTitle: '',
              actionType: 'SET_NEXT_LEVEL_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Week One',
                  subTitle: '30 Day Ball Mastery Week 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Week Two',
                  subTitle: '30 Day Ball Mastery Week 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Week Three',
                  subTitle: '30 Day Ball Mastery Week 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Week Four',
                  subTitle: '30 Day Ball Mastery Week 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'No More Weak Foot',
              subTitle: '',
              actionType: 'SET_NO_MORE_WEAK_FOOT_DATA',
              children: [
                {
                  title: 'P1: Right Foot Only',
                  subTitle: '30 Day No Weak Foot 01 Right',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Left Foot Only',
                  subTitle: '30 Day No Weak Foot 02 Left Foot',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Both Feet',
                  subTitle: '30 Day No Weak Foot 03 Both Feet',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Tight Space Sessions',
              subTitle: '',
              actionType: 'SET_TIGHT_SPACE_SESSIONS_DATA',
              children: [
                {
                  title: 'P1: Week One',
                  subTitle: '30 Day Tight Space Week 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Week Two',
                  subTitle: '30 Day Tight Space Week 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Week Three',
                  subTitle: '30 Day Tight Space Week 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Week Four',
                  subTitle: '30 Day Tight Space Week 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Fitness & Ball Mastery',
              subTitle: '',
              actionType: 'SET_FITNESS_AND_BALL_MASTERY_DATA',
              children: [
                {
                  title: 'P1: Week One',
                  subTitle: '30 Day Fitness Week 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Week Two',
                  subTitle: '30 Day Fitness Week 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Week Three',
                  subTitle: '30 Day Fitness Week 3',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Week Four',
                  subTitle: '30 Day Fitness Week 4',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: '7 Day Skills Challenge',
          subTitle: '',
          actionType: 'SET_7_DAY_SKILLS_CHALLENGE_DATA',
          children: [
            {
              title: '7 Day Skills Challenge',
              subTitle: '',
              actionType: 'SET_7_DAY_SKILLS_CHALLENGE_CHILD_DATA',
              children: [
                {
                  title: 'P1: 7 Day Skills Challenge Program',
                  subTitle: 'Free Section (Free) 7-Day',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
        {
          title: `Beginner's Essentials`,
          subTitle: '',
          actionType: 'SET_BEGINNERS_ESSENTIALS_CATEGORY_DATA',
          children: [
            {
              title: `Beginner's Essentials`,
              subTitle: '',
              actionType: 'SET_BEGINNER_ESSENTIALS_SUBCATEGORY_DATA',
              children: [
                {
                  title: 'P1: Ball Mastery',
                  subTitle: 'Ball Mastery Beginners',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Juggling',
                  subTitle: 'Juggling Beginners',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Dribbling',
                  subTitle: 'Dribbling Beginners',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Passing',
                  subTitle: 'Passing Beginners',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '1v1 Games & Challenges',
      subTitle: '',
      actionType: 'SET_1V1_GAMES_AND_CHALLENDGES_DATA',
      children: [
        {
          title: '1v1 Games & Challenges',
          subTitle: '',
          actionType: 'SET_1V1_GAMES_AND_CHALLENDGES_CHILD_DATA',
          children: [
            {
              title: '1v1 Fun Games',
              subTitle: '',
              actionType: 'SET_SET_1V1_FUND_GAMES_DATA',
              children: [
                {
                  title: 'P1: Keeper vs Striker 1v1',
                  subTitle: '1V1 Finishing Games 1 part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Dribbling Chase & Finish',
                  subTitle: '1V1 Finishing Games 2 part',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: More 1v1 Games',
                  subTitle: '1V1%20Soccer%20Chasing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P4: Soccer Tagging',
                  subTitle: 'Chasing Tagging Games Playing',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P5: Soccer Chasing',
                  subTitle: 'Soccer Chasing Main',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P6: Soccer Racing Part 1',
                  subTitle: 'Games Playing, Racing Part 1',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P7: Soccer Racing Part 2',
                  subTitle: 'Games Playing, Racing Part 2',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P8: Play Practice Play',
                  subTitle: 'Games Playing, Practising',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Skills Challenges',
              subTitle: '',
              actionType: 'SET_SKILL_CHALLENGES_DATA',
              children: [
                {
                  title: 'P1: Ball Mastery Challenges',
                  subTitle: 'Anytime Skills 01 Ball Mastery',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P2: Juggling Challenges',
                  subTitle: 'Anytime Skills 02 Juggle Master',
                  actionType: SubfolderPageUrl.videoSeries,
                },
                {
                  title: 'P3: Figure 8 Dribbling Challenges',
                  subTitle: 'Anytime Skills 03 Figure 8',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
            {
              title: 'Skills Library',
              subTitle: '',
              actionType: 'SET_SKILLS_LIBRARY_DATA',
              children: [
                {
                  title: 'P1: Sample Skills',
                  subTitle: 'Summary Anytime skills',
                  actionType: SubfolderPageUrl.videoSeries,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: `Hidden Folders`,
      subTitle: '',
      actionType: 'SET_MY_HIDDEN_DATA',
      children: [
        {
          title: `Hidden Folders`,
          subTitle: '',
          actionType: 'SET_MY_HIDDEN_CATEGORY_DATA',
          children: [
            {
              title: `Hidden Folders`,
              subTitle: '',
              actionType: 'SET_MY_HIDDEN_SUBCATEGORY_DATA',
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

const listViewReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ADD_FAVORITE_SUBCATEGORY: {
      const { children } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_FAVORITE_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_FAVORITE_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children.push(children);
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case SET_FAVORITE_SUBCATEGORY: {
      const { children } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_FAVORITE_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_FAVORITE_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children = children;
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }

    case 'DELETE_FAVORITE': {
      const { subTitle, title } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_FAVORITE_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_FAVORITE_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children = subCategory.children.filter((favorite) => {
                    if (!favorite.subTitle) {
                      return favorite.title !== title;
                    }

                    return favorite.subTitle !== subTitle;
                  });
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case ADD_HOMEWORK: {
      const { children, team } = action.payload;
      const teamId = team.id;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_HOMEWORK_DATA') {
          const categoryIndex = work.children.findIndex(
            (category) => category.actionType === `cat-${teamId}`,
          );

          if (categoryIndex === -1) {
            // Category does not exist, create a new category and subcategory
            work.children = [
              ...work.children,
              {
                actionType: `cat-${teamId}`,
                title: team.name,
                subTitle: '',
                children: [
                  {
                    actionType: `subcat-${teamId}`,
                    title: team.name,
                    subTitle: '',
                    children: [children],
                  },
                ],
              },
            ];
          } else {
            // Category exists, update the existing subcategory
            const subCategoryIndex = work.children[categoryIndex].children.findIndex(
              (subcat) => subcat.actionType === `subcat-${teamId}`,
            );

            if (subCategoryIndex === -1) {
              // Subcategory does not exist, create it
              work.children[categoryIndex].children.push({
                actionType: `subcat-${teamId}`,
                title: team.name,
                subTitle: '',
                children: [children],
              });
            } else {
              // Subcategory exists, update it
              work.children[categoryIndex].children[subCategoryIndex].children.push(children);
            }
          }

          return work;
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case SET_HOMEWORK: {
      const { children } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_HOMEWORK_DATA') {
          return {
            ...work,
            children,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case DELETE_HOMEWORK: {
      const { children, team } = action.payload;
      const { title, subTitle } = children;

      const updatedData = state.data.map((work) => {
        if (work.actionType !== 'SET_MY_HOMEWORK_DATA') {
          return work;
        }

        return {
          ...work,
          children: work.children.map((category) => {
            if (category.actionType !== `cat-${team.id}`) {
              return category;
            }

            return {
              ...category,
              children: category.children.map((item) => {
                if (item.actionType !== `subcat-${team.id}`) {
                  return item;
                }

                return {
                  ...item,
                  children: item.children.filter((homework) => {
                    return !(
                      homework.title.trim().toLowerCase() === title.trim().toLowerCase() &&
                      homework.subTitle.trim().toLowerCase() === subTitle.trim().toLowerCase()
                    );
                  }),
                };
              }),
            };
          }),
        };
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case ADD_HIDDEN: {
      const { children } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_HIDDEN_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_HIDDEN_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_HIDDEN_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children.push(children);
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case DELETE_HIDDEN: {
      const { subTitle, title } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_HIDDEN_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_HIDDEN_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_HIDDEN_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children = subCategory.children.filter((favorite) => {
                    if (!favorite.subTitle) {
                      return favorite.title !== title;
                    }

                    return favorite.subTitle !== subTitle;
                  });
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case SET_HIDDEN: {
      const { children } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_HIDDEN_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_HIDDEN_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_HIDDEN_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children = children;
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case SET_MY_TRAINING_SESSIONS_SUBCATEGORY: {
      const { children } = action.payload;

      const updatedData = state.data.map((work) => {
        if (work.actionType === 'SET_MY_SESSION_DATA') {
          const updatedChildren = work.children.map((category) => {
            if (category.actionType === 'SET_MY_SESSION_CATEGORY_DATA') {
              const updatedCategory = category.children.map((item) => {
                if (item.actionType === 'SET_MY_SESSION_SUBCATEGORY_DATA') {
                  const subCategory = item;
                  subCategory.children = children;
                }
                return item;
              });
              return {
                ...category,
                children: updatedCategory,
              };
            }
            return category;
          });
          return {
            ...work,
            children: updatedChildren,
          };
        }
        return work;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    default:
      return state;
  }
};

export const addFavorite = (favorite) => {
  return {
    type: ADD_FAVORITE_SUBCATEGORY,
    payload: {
      children: favorite,
    },
  };
};

export const deleteFavorite = (favorite) => {
  return {
    type: 'DELETE_FAVORITE',
    payload: favorite,
  };
};

export const setFavorites = (favorites) => ({
  type: SET_FAVORITE_SUBCATEGORY,
  payload: {
    children: favorites,
  },
});

export const addHomework = (item, team) => {
  return {
    type: ADD_HOMEWORK,
    payload: {
      children: item,
      team,
    },
  };
};

export const removeHomework = (item, team) => {
  return {
    type: DELETE_HOMEWORK,
    payload: {
      children: item,
      team,
    },
  };
};

export const setHomework = (children) => {
  return {
    type: SET_HOMEWORK,
    payload: {
      children,
    },
  };
};

export const addHiddenFolder = (item) => {
  return {
    type: ADD_HIDDEN,
    payload: {
      children: item,
    },
  };
};
export const removeHiddenFolder = (favorite) => {
  return {
    type: DELETE_HIDDEN,
    payload: favorite,
  };
};

export const setHiddenFolder = (children) => {
  return {
    type: SET_HIDDEN,
    payload: {
      children,
    },
  };
};

export const setTrainingSessions = (children) => ({
  type: SET_MY_TRAINING_SESSIONS_SUBCATEGORY,
  payload: {
    children: children,
  },
});

export const getCategoryByAction = (actionType) => {
  const array = listViewReducer().data;
  for (let i = 0; i < array.length; i++) {
    if (array[i].actionType !== actionType) {
      const lowerArray = array[i].children;
      for (let i = 0; i < lowerArray.length; i++) {
        if (lowerArray[i].actionType === actionType) {
          return lowerArray[i];
        }
      }
    } else {
      return array[i];
    }
  }
};

export const getVideoCategoriesByAction = (actionType) => {
  const array = listViewReducer().data;
  for (let i = 0; i < array.length; i++) {
    if (array[i].actionType !== actionType) {
      const lowerArray = array[i].children;
      for (let i = 0; i < lowerArray.length; i++) {
        if (lowerArray[i].actionType === actionType) {
          return lowerArray[i].children;
        }
      }
    }
  }
};

export default listViewReducer;
