import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './VideoSeries.module.sass';
import VideoSeriesRow from './VideoseriesRow';
import { withRouter, Link } from 'react-router-dom';
import { message } from 'antd';
import { compose } from 'redux';
import {
  listOfCategoriesVimeo,
  listOfVideosVimeo,
  resetAllWatchedVideoInCategory,
} from '../../../api/api';
import { parentRedirect, haveNoLoginRedirect } from '../../../hoc/redirectComp';
import PaginationLogic from './Pagination';
import s from '../../../app.module.sass';
import { Spinner } from 'reactstrap';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { CHILD_TOKEN, SubfolderPageUrl, VIMEO_CHILD_CATEGORIES } from '../../../constants/const';
import { SettingOutlined } from '@ant-design/icons';
import useMySessionFiles from '../../../hooks/useMySessionFiles';

const VideoSeries = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [requestPayload, setRequestPayload] = useState(false);
  const [upLoadedData, setUpLoadedData] = useState(false);
  const [upLoadedCategoriesData, setUpLoadedCategoriesData] = useState(false);
  const history = useHistory();
  const isComponentUnmounted = null;
  const params = useParams();
  const { filesData, refetchFiles } = useMySessionFiles();

  // проверка костыль с названием
  const pageName = props.match.params.videoId || 'Summary Anytime skills';
  let videoFolderArrayForCurrentPage = null;
  // useEffect для for loading folders data (video categories)
  useEffect(() => {
    if (!props.vimeoFolderData.length) {
      try {
        listOfCategoriesVimeo(CHILD_TOKEN, VIMEO_CHILD_CATEGORIES).then((response) => {
          if (!isComponentUnmounted) {
            requestVideoCategoriesHandler(response);
          }
        });
      } catch (e) {
        console.log(e);
        history.push(SubfolderPageUrl.dashboard);
      }
    } else {
      setUpLoadedCategoriesData(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      pageName === 'Summary Anytime skills' ||
      (!props.vimeoVideoData.total && upLoadedCategoriesData) ||
      params?.fromListViewPage
    ) {
      try {
        let currentFolderUri = null;
        if (!props.currentUri || params?.fromListViewPage) {
          videoFolderArrayForCurrentPage = props.vimeoFolderData.filter((videoFolderElement) =>
            videoFolderElement.name.includes(pageName),
          );

          currentFolderUri = videoFolderArrayForCurrentPage[0]?.uri;

          console.log({ pageName, videoFolderArrayForCurrentPage, video: props.vimeoFolderData });
        } else {
          currentFolderUri = props.currentUri;
        }
        props.setUri(currentFolderUri);
        listOfVideosVimeo(currentFolderUri, currentPage).then((response) =>
          requestHandler(response),
        );
      } catch (e) {
        console.log(e);
        history.push(SubfolderPageUrl.dashboard);
      }
    } else if (props.vimeoVideoData.total) {
      setUpLoadedData(true);
    }
  }, [upLoadedCategoriesData, currentPage]);

  useScrollToTop();

  const numOfPages = props.vimeoVideoData.total;
  const pages = Math.ceil(numOfPages / 5);

  const requestVideoCategoriesHandler = (response) => {
    response.json().then((result) => {
      props.setChildDashVimeoInfo(
        result.data.map((a) => {
          return { uri: a.uri, name: a.name };
        }),
      );
      setUpLoadedCategoriesData(true);
    });
  };

  const changePage = (direction) => {
    if (direction === 'prev' && 1 <= currentPage - 1) {
      setCurrentPage(currentPage - 1);
      lessonsRequest(currentPage - 1);
    } else if (direction === 'next' && pages >= currentPage + 1) {
      setCurrentPage(currentPage + 1);
      lessonsRequest(currentPage + 1);
    }
  };

  const lessonsRequest = (e) => {
    if (!requestPayload) {
      setRequestPayload(true);
      setCurrentPage(e);
      onChange(e, props.currentUri);
    }
  };

  const onChange = (event, uri) => {
    try {
      listOfVideosVimeo(uri, event).then((response) => requestHandler(response));
    } catch (e) {
      console.log(e);
    }
  };

  const requestHandler = (response) => {
    setRequestPayload(false);
    if (response.status === 200) {
      response.json().then((result) => {
        const videoArray = result?.data?.map((a) => {
          return { name: a.name, link: a.link, viewed: a.viewed };
        });
        props.setVideo({ total: result.total, videoArray });
        setUpLoadedData(true);
      });
    } else {
      message.error('status: ' + response.status + ` ${response.statusText}`);
    }
  };

  const resetCategory = () => {
    try {
      resetAllWatchedVideoInCategory(props.currentUri).then((r) => resetHandler(r));
    } catch (e) {
      console.log(e);
    }
  };

  const resetHandler = (response) => {
    if (response.status === 200) {
      props.setVideo({
        total: props.vimeoVideoData.total,
        videoArray: props.vimeoVideoData.videoArray.map((a) => ({
          name: a.name,
          link: a.link,
          viewed: false,
        })),
      });
    }
  };

  return (
    <>
      {upLoadedData ? (
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.section__flexContainer}>
              <div className={styles.title}>
                {pageName === '1V1 Soccer Chasing'
                  ? '1V1'
                  : pageName === 'Free Games &'
                  ? 'Free Games'
                  : pageName}
              </div>
              <button className={styles.grayBtn + ' ' + styles.reset} onClick={resetCategory}>
                Reset All Completion Status
              </button>
            </div>
          </section>
          <div className={styles.section__flexContainer_training}>
            <div className={styles.subtitle}>Training Videos</div>
            <Link className={styles.button__reward} to="/my-training-sessions">
              <SettingOutlined />
              <span className={styles.button__title}>Manage Session</span>
            </Link>
          </div>
          <section className={styles.section}>
            {props.vimeoVideoData.videoArray?.map((a, index) => (
              <VideoSeriesRow
                count={index}
                text={a.name}
                link={a.link}
                key={a.name + index}
                viewed={a.viewed}
                setViewed={props.setViewed}
                categoryName={'vimeoVideoData'}
                refetchFiles={refetchFiles}
                pinned={filesData.some((file) => file.link === a.link)}
              />
            ))}
            <PaginationLogic
              currentPage={currentPage}
              request={lessonsRequest}
              changePage={changePage}
              pages={pages}
            />
          </section>
        </div>
      ) : (
        <div style={{ background: '#f4fbff', minHeight: '80vh' }}>
          <Spinner color="primary" className={s.spinner} />
        </div>
      )}
    </>
  );
};

export default compose(withRouter, parentRedirect, haveNoLoginRedirect)(VideoSeries);
