import React, { useState } from 'react';
import styles from './index.module.sass';
import { ButtonSessions, TEAMS_DESCRIPTION, MOBILE_TEAM_DESCRIPTION } from '../../constants/teams';
import { MOBILE_DIMENSION } from '../../constants/const';
import { ButtonList } from '../DashboardChild/Teams/ButtonList/ButtonList';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import List from './List';
import { useSessionFolder } from '../../hooks/useSessionFolder';
import { CreateSession } from './Create';
import FindSession from './Find';
import { Spin } from 'antd';
import ASTPagination from './ASTPagination';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { parentRedirect, haveNoLoginRedirect } from '../../hoc/redirectComp';

const defaultScreen = Object.keys(ButtonSessions)[0];
const findSession = Object.keys(ButtonSessions)[1];
const createSession = Object.keys(ButtonSessions)[2];

const MyTrainingSessions = () => {
  const [chosenScreen, setChosenScreen] = useState(defaultScreen);
  const { data, isFetching, meta, page, setPage, handleAddFolder } = useSessionFolder();
  const { width } = useWindowDimensions();

  const totalPages = Math.ceil(meta?.total / meta?.limit);

  return (
    <div className={styles.container}>
      <section className={styles.main_screen}>
        <header className={styles.main_screen__header}>
          <h2
            className={styles.main_screen__title}
            style={{
              textTransform: 'capitalize',
            }}
          >
            {chosenScreen}
          </h2>
          <p className={styles.main_screen__promo}>
            Create, search, and save custom training sessions directly to your profile.
          </p>
          <ul className={styles.buttons}>
            <ButtonList chosenScreen={chosenScreen} changeChosenScreen={setChosenScreen}>
              {ButtonSessions}
            </ButtonList>
          </ul>
        </header>
        <div
          style={{
            borderBottom: 'solid thin',
            width: '100%',
            borderBottomColor: 'lightgrey',
            marginBottom: '10px',
          }}
        />
        <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
          {chosenScreen === defaultScreen && (
            <Spin spinning={isFetching} style={{ width: '100%' }}>
              <List data={data} view="my-session" />
              <div
                style={{
                  marginBottom: '10px',
                }}
              />
              <ASTPagination
                maxPage={totalPages}
                currentPage={page}
                setCurrentPage={setPage}
                limit={meta?.limit || 1}
              />
            </Spin>
          )}
          {chosenScreen === createSession && (
            <CreateSession
              onClose={() => setChosenScreen(defaultScreen)}
              handleSubmit={handleAddFolder}
            />
          )}
          {chosenScreen === findSession && <FindSession />}
        </div>
      </section>
    </div>
  );
};

export default compose(withRouter, parentRedirect, haveNoLoginRedirect)(MyTrainingSessions);
