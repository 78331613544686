import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Homework.module.sass';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PinRed from '../../ListView/pin_red.png';
import PinGrey from '../../ListView/pin_grey.png';
import useUserTeams from '../../../../hooks/useUserTeam';
import { useVideoCategoriesByAction } from '../../../../hooks/useVideoCategoriesByAction';
import { CloseOutlined, RollbackOutlined } from '@ant-design/icons';
import {
  deleteFavorite,
  addFavorite,
  addHomework,
  removeHomework,
} from '../../../../Redux/listViewReducer';
import bellIcon from './bell.png';

import {
  createFavoriteJourney,
  deleteFavoriteBySubTitle,
  createHomework,
  deleteHomework,
  notifyHomework,
  createHomeworkReport,
  deleteHomeworkReport,
  deleteHomeworkReportByUser,
  deleteHomeworkReportByTeamId,
} from '../../../../api/api';
import { ModalAssignment } from '../../../ProfilePage/ModalWindowPopap/ModalAssignment';
import { message, Skeleton } from 'antd';
import { ModalHomeworkTag } from '../../../ProfilePage/ModalWindowPopap/ModalHomeworkTag';
import { CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import useHomeworkReports from '../../../../hooks/useHomeworkReports';
import useUserHomeworkReports from '../../../../hooks/useUserHomeworkReport';
import { Avatars } from '../../../../constants/const';

const Homework = ({ currentTeam }) => {
  const {
    isLoading,
    data: homeworkReports,
    refetch: homeworkRefetch,
  } = useHomeworkReports(currentTeam.id);
  const {
    isLoading: userReportsLoading,
    data: userReports,
    refetch: userReportsRefetch,
  } = useUserHomeworkReports();
  const { data } = useSelector((state) => state.listViewReducer);
  const favoriteData = useVideoCategoriesByAction('SET_MY_FAVORITE_CATEGORY_DATA').find(
    (item) => item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA',
  ).children;
  const homework = data
    ?.find((item) => item.actionType === 'SET_MY_HOMEWORK_DATA')
    .children.find((item) => item.actionType === `cat-${currentTeam.id}`);
  const { manageTeams, currentTeamStats } = useUserTeams();
  const playerID = useSelector((state) => state.playerDataReducer.playerData.id);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNotified, setIsNotified] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);
  const [selectedHomework, setSelectedHomework] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedView, setSelectedView] = useState('homework');

  const checkAccess = () =>
    currentTeamStats.managers.some((element) => element.childId === playerID);
  const isPlayerManager = currentTeamStats.players.some(
    (element) => element.childId === playerID && element.role === 'player_manager',
  );
  const isManager = checkAccess();

  const homeworkData = useMemo(() => {
    const allHomework = data
      ?.find((item) => item.actionType === 'SET_MY_HOMEWORK_DATA')
      .children.map((item) => item.children[0].children)
      .flat();

    // Use reduce to filter out duplicate entries based on title and subTitle
    const uniqueHomework = allHomework.reduce((acc, current) => {
      const duplicate = acc.find(
        (item) => item?.title === current?.title && item?.subTitle === current?.subTitle,
      );
      if (!duplicate) {
        return [...acc, current];
      }
      return acc;
    }, []);

    return uniqueHomework;
  }, [data]);

  const checkFavorite = useCallback(
    (_item) => {
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      return favoriteCheck;
    },
    [favoriteData],
  );

  const checkHomeworkWithFavorite = useCallback(
    (_item) => {
      const homeworkCheck = homeworkData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      return manageTeams.length > 0 ? homeworkCheck || favoriteCheck : favoriteCheck;
    },
    [favoriteData, homeworkData, manageTeams],
  );

  const handleFavoriteClick = useCallback(
    async (item) => {
      if (manageTeams.length > 0) {
        setSelectedItem(item);
        return;
      }

      if (manageTeams.length === 0) {
        if (checkFavorite(item)) {
          await deleteFavoriteBySubTitle(item).then((res) => {
            console.log(res.msg);
            dispatch(deleteFavorite(item));
          });
        } else {
          await createFavoriteJourney(item).then((res) => {
            dispatch(addFavorite(res.favorite));
          });
        }
      }
    },
    [checkFavorite, manageTeams, dispatch],
  );

  const checkHomeworkWithTeam = useCallback(
    (team, _item) => {
      const teamData = data
        ?.find((item) => item.actionType === 'SET_MY_HOMEWORK_DATA')
        ?.children.find((item) => item.actionType === `cat-${team.id}`)?.children[0].children;

      const homeworkCheck = teamData?.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      return homeworkCheck;
    },
    [data],
  );

  const onSubmit = useCallback(
    async (teams, addToFavorite) => {
      // Remove homework from teams that have been unchecked
      const teamsToRemoveHomework = manageTeams.filter(
        (team) =>
          checkHomeworkWithTeam(team, selectedItem) &&
          !teams.some((selectedTeam) => selectedTeam.id === team.id),
      );

      for (const team of teamsToRemoveHomework) {
        await deleteHomework({ ...selectedItem, team }).then((res) => {
          console.info(res.msg);
          dispatch(removeHomework(selectedItem, team));
        });
      }

      // Add homework to newly selected teams
      for (const team of teams) {
        if (!checkHomeworkWithTeam(team, selectedItem)) {
          await createHomework({ ...selectedItem, teamId: team.id }).then((res) => {
            console.info(res.success);
            dispatch(addHomework(selectedItem, team));
          });
        }
      }

      // Handle favorites
      if (checkFavorite(selectedItem) === false && addToFavorite === true) {
        await createFavoriteJourney(selectedItem).then((res) => {
          dispatch(addFavorite(res.favorite));
        });
      }

      if (checkFavorite(selectedItem) === true && addToFavorite === false) {
        await deleteFavoriteBySubTitle(selectedItem).then((res) => {
          console.log(res.msg);
          dispatch(deleteFavorite(selectedItem));
        });
      }

      setSelectedItem(null);
    },
    [checkFavorite, checkHomeworkWithTeam, selectedItem, dispatch, manageTeams],
  );

  const handleNotifyMember = async () => {
    setIsNotifying(true);
    await notifyHomework(currentTeam.id).then((res) => {
      setIsNotifying(false);
      setIsNotified(true);
      message.success('Notification sent successfully');

      console.log({ res });

      setTimeout(() => {
        setIsNotified(false);
      }, 5000);
    });
  };

  const handleSelectedTags = (tag) => () => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleSelectedView = () => {
    setSelectedView((prev) => (prev === 'homework' ? 'report' : 'homework'));
  };

  const { mutate: handleReportDone } = useMutation({
    mutationFn: async (param) => {
      return await createHomeworkReport(param);
    },
    onSettled: () => {
      homeworkRefetch();
      userReportsRefetch();
    },
    onSuccess: () => {
      message.success('Successfully done Homework Report');
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to done Homework Report');
    },
  });

  const { mutate: handleDeleteReport } = useMutation({
    mutationFn: async (param) => {
      return await deleteHomeworkReport(param);
    },
    onSettled: () => {
      homeworkRefetch();
      userReportsRefetch();
    },
    onSuccess: () => {
      message.success('Successfully Undone Homework Report');
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to Undone Homework Report');
    },
  });

  const { mutate: handleDeleteReportByUser } = useMutation({
    mutationFn: async (param) => {
      return await deleteHomeworkReportByUser(param);
    },
    onSettled: () => {
      homeworkRefetch();
      userReportsRefetch();
    },
    onSuccess: () => {
      message.success('Successfully Clear Homework Report');
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to Clear Homework Report');
    },
  });

  const { mutate: handleDeleteReportByTeam } = useMutation({
    mutationFn: async (param) => {
      return await deleteHomeworkReportByTeamId(param);
    },
    onSettled: () => {
      homeworkRefetch();
      userReportsRefetch();
    },
    onSuccess: () => {
      message.success('Successfully Cleared All Homework Report');
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to Clear All Homework Report');
    },
  });

  if (isLoading && userReportsLoading) {
    return <Skeleton active />;
  }

  return (
    <div className={styles.homework}>
      {homework?.children.map((category) => (
        <div key={category.title} className={styles.videoCategory}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px',
            }}
          >
            <p className={styles.videoCatogoryHeader}>
              {selectedView === 'report' ? 'Homework Report' : category.title}
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <button
                type="button"
                className={styles.tagButton}
                onClick={handleSelectedView}
                style={{
                  fontSize: '18px',
                }}
              >
                {selectedView === 'report' ? 'Back to Team Homework' : 'Homework Report'}
              </button>
              {(isManager || isPlayerManager) && (
                <button
                  className={styles.button}
                  disabled={isNotifying}
                  onClick={handleNotifyMember}
                >
                  <span className={styles.desktopText}>
                    {isNotified ? 'Notification Sent' : 'Notify Members'}
                  </span>
                  <img src={bellIcon} alt="Notify" className={styles.mobileIcon} />
                </button>
              )}
            </div>
          </div>
          {selectedView === 'homework' ? (
            <div className={styles.videoCluster}>
              {selectedTags.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    background: 'rgb(244, 251, 255)',
                    width: '100%',
                    padding: '1.2rem 1.5rem .8rem 1.5rem',
                    borderBottom: '1px solid #bfcdd9',
                  }}
                >
                  <div
                    style={{
                      color: '#164162',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '6px',
                    }}
                  >
                    Tag Filter:{' '}
                    {selectedTags.map((tag, i) => (
                      <span
                        key={`${tag}-${i}`}
                        style={{
                          color: '#bfcdd9',
                        }}
                      >
                        #{tag}
                      </span>
                    ))}{' '}
                    <span
                      style={{
                        color: '#bfcdd9',
                      }}
                    >
                      |
                    </span>
                    <button
                      type="button"
                      className={styles.tagButton}
                      style={{
                        border: 'none',
                        fontSize: 'inherit',
                        marginLeft: '0px',
                      }}
                      onClick={() => setSelectedTags([])}
                    >
                      <CloseOutlined
                        style={{
                          fontSize: '12px',
                        }}
                      />{' '}
                      clear
                    </button>
                  </div>
                </div>
              )}
              <ul className={styles.listFolders}>
                {category?.children
                  ?.filter((item) => {
                    if (selectedTags.length === 0) {
                      return true;
                    } else {
                      return (
                        Array.isArray(item.tags) &&
                        item.tags.some((tag) => selectedTags.includes(tag))
                      );
                    }
                  })
                  .map((child) => {
                    let link = '';

                    if (!child?.actionType || child.type === 'folder') {
                      // Determine the link based on folder presence
                      link = `/my-training-sessions/${child?.folderId || child.id}`;
                    } else {
                      // Determine the link for non-folder items
                      const basePath = child?.subFolder || child.actionType;
                      const title = child?.subFolder ? child.title : child.subTitle;
                      link = `${basePath}/${title}/true`;
                    }

                    return (
                      <li
                        key={child.title}
                        className={styles.listRow}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className={styles.listRow}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div className={styles.listItem}>
                            <img
                              className={styles.pin}
                              src={
                                checkHomeworkWithFavorite(child) || checkFavorite(child)
                                  ? PinRed
                                  : PinGrey
                              }
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleFavoriteClick(child)}
                            />
                            <Link to={link} className={styles.textListItem}>
                              {child.title}
                            </Link>
                          </div>
                          <div
                            className={styles.listItemRow}
                            style={{
                              display: 'flex',
                              gap: '5px',
                              flexWrap: 'wrap',
                              paddingRight: '30px',
                              paddingLeft: '30px',
                            }}
                          >
                            {child?.tags && child.tags.length > 0 ? (
                              <>
                                {child.tags.map((item, i) => (
                                  <button
                                    className={styles.tagButton}
                                    type="button"
                                    key={i}
                                    onClick={handleSelectedTags(item)}
                                    style={{
                                      color: selectedTags.includes(item) ? '#bfcdd9' : '#e1343f',
                                      borderBottom: `1px solid ${
                                        selectedTags.includes(item) ? '#bfcdd9' : '#e1343f'
                                      }`,
                                    }}
                                  >
                                    #{item}
                                    {child.tags.length - 1 === i ? '' : ','}
                                  </button>
                                ))}
                              </>
                            ) : null}
                            {isManager && (
                              <button
                                className={styles.tagButton}
                                type="button"
                                onClick={() => setSelectedHomework(child)}
                              >
                                + Tags
                              </button>
                            )}
                          </div>
                        </div>

                        <button
                          className={styles.doneButton}
                          onClick={() => {
                            const report = userReports.find(
                              (report) => report.homeworkId === child.id,
                            );
                            if (report) {
                              handleDeleteReport(report.id);
                              return;
                            } else {
                              handleReportDone({ teamId: child.teamId, homeworkId: child.id });
                              return;
                            }
                          }}
                          style={{ gap: '5px' }}
                        >
                          <span style={{ display: 'flex', gap: '6px' }}>
                            {userReports.length > 0 &&
                            userReports.some((report) => report.homeworkId === child.id) ? (
                              <>Undone</>
                            ) : (
                              <>
                                <CheckOutlined />
                                Done
                              </>
                            )}
                          </span>
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  padding: '0 12px',
                }}
              >
                {(isManager || isPlayerManager) && (
                  <button
                    type="button"
                    onClick={() => handleDeleteReportByTeam(currentTeam.id)}
                    style={{
                      display: 'flex',
                      fontSize: '18px',
                      marginBottom: '10px',
                      borderBottom: 'none',
                      alignSelf: 'flex-end',
                      color: '#e1343f',
                      outline: 'none',
                      fontWeight: 600,
                      border: 'none',
                      background: 'transparent',
                      textTransform: 'uppercase',
                    }}
                  >
                    Clear All
                  </button>
                )}
              </div>
              <div className={styles.videoCluster}>
                <ul className={styles.listFolders}>
                  {homeworkReports.map((item, i) => {
                    return (
                      <li
                        key={i}
                        className={styles.listRow}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className={styles.listRow}
                          style={{
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          <div className={styles.listItem}>
                            <img
                              src={item.user.image || Avatars[parseInt(item.user.picture) - 1]}
                              className={styles.avatar_logo}
                              alt=""
                            />
                            <span
                              className={styles.textListItem}
                              style={{
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.user.childFirstName} {item.user?.childLastName}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              flexDirection: 'column',
                            }}
                          >
                            {item.reports.map((report) => {
                              return (
                                <div key={report.id} className={styles.listItem}>
                                  {report.homework.title}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {(playerID === item.user.teamChild || isManager) && (
                          <button
                            type="button"
                            className={styles.tagButton}
                            style={{
                              border: 'none',
                              fontSize: '16px',
                              marginLeft: '0px',
                              display: 'flex',
                              gap: '6px',
                              alignItems: 'center',
                            }}
                            onClick={() =>
                              handleDeleteReportByUser({
                                teamId: currentTeam.id,
                                userId: item.user.id,
                              })
                            }
                          >
                            <RollbackOutlined /> Clear
                          </button>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
        </div>
      ))}
      {selectedItem && (
        <ModalAssignment
          manageTeams={manageTeams}
          onClose={() => setSelectedItem(null)}
          initFav={checkFavorite(selectedItem)}
          onSubmit={onSubmit}
          selectedItem={selectedItem}
        />
      )}
      {selectedHomework && (
        <ModalHomeworkTag
          onClose={() => setSelectedHomework(null)}
          homeworkId={selectedHomework.id}
        />
      )}
    </div>
  );
};

export default Homework;
