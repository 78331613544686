import React from 'react';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './index.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';

export function DeleteSession({
  handleSubmit,
  onClose,
  data,
  title = 'Delete Training Session?',
  message = 'Are you sure you want to delete this training session?',
}) {
  const handleClosePopupButtonClick = () => {
    onClose();
  };

  const submitForm = () => {
    handleSubmit(data.id, {
      onSettled: () => handleClosePopupButtonClick(),
    });
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.create_form}>
        <ClosePopupButton handleClosePopupButtonClick={handleClosePopupButtonClick} />
        <h2 className={styles.create_form__title}>{title}</h2>
        <div className={styles.flex_container}>
          <form className={styles.form}>
            <label className={styles.form__label}>{message}</label>
            <p className={styles.form__importance}>This action cannot be reversed.</p>
            <div className={styles.buttons}>
              <BorderedButton
                handleButtonClick={handleClosePopupButtonClick}
                width={'20rem'}
              ></BorderedButton>
              <RedButton
                handleButtonClick={submitForm}
                title={`Delete`}
                width={'20rem'}
              ></RedButton>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
