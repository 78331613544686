import { useMemo, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addFolder,
  getOpenFoldersByChildId,
  closeFolder,
  pinnedFolder,
  updateFolder,
  bookmarkFolder,
} from '../api/api';
import { message } from 'antd';

export const useSessionFolder = () => {
  const [filters, setFilters] = useState({});
  const [searchField, setSearchField] = useState('');
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('-pinned,-updatedAt');

  const [sortDirection, setSortDirection] = useState('desc');
  const [fields, setFields] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // Construct query parameters
  const params = {
    ...(Object.keys(filters).length && { filters: JSON.stringify(filters) }),
    ...(searchField && keyword && { searchField, keyword }),
    ...(sortBy && typeof sortBy === 'string' && { sortBy }), // Validate sortBy is a string
    ...(fields && { fields }),
    page,
    limit,
  };

  const queryKey = ['my-open-folders', params];

  const queryKeyMyFolders = ['my-folders'];

  const {
    isLoading: isFolderLoading,
    data: foldersData,
    error: folderError,
    refetch: refetchFolder,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: async () => await getOpenFoldersByChildId(params),
    enabled: !!queryKey,
  });

  const queryClient = useQueryClient();

  const invalidateFoldersQuery = () => {
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries(queryKeyMyFolders);
  };

  const { mutate: handleAddFolder } = useMutation({
    mutationFn: async (folderData) => await addFolder(folderData),
    onSuccess: () => {
      message.success('Folder added successfully');
      refetchFolder();
      invalidateFoldersQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to add folder');
    },
  });

  const { mutate: handleUpdateFolder } = useMutation({
    mutationFn: async (folderData) => await updateFolder(folderData),
    onSuccess: () => {
      message.success('Folder updated successfully');
      refetchFolder();
      invalidateFoldersQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to update folder');
    },
  });

  const { mutate: handleCloseFolder } = useMutation({
    mutationFn: async (id) => await closeFolder(id),
    onSuccess: () => {
      message.success('Folder closed successfully');
      refetchFolder();
      invalidateFoldersQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to close folder');
    },
  });

  const { mutate: handlePinnedFolder } = useMutation({
    mutationFn: async (id) => await pinnedFolder(id),
    onSuccess: (data) => {
      message.success(`Folder ${data?.pinned ? 'pinned' : 'unpinned'} successfully`);
      refetchFolder();
      invalidateFoldersQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to pin folder');
    },
  });

  const data = useMemo(() => foldersData?.data || [], [foldersData]);

  const handleSort = (column) => {
    setSortDirection((prevDirection) => {
      const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
      const sortQuery = newDirection === 'desc' ? `-${column}` : column;

      // Update the sortBy value
      setSortBy(sortQuery);

      return newDirection; // Return the new direction
    });
  };

  const { mutate: handleBookmarkFolder } = useMutation({
    mutationFn: async (id) => await bookmarkFolder(id),
    onSuccess: (data) => {
      message.success(data);
      refetchFolder();
      invalidateFoldersQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to bookmark folder');
    },
  });

  return {
    isFolderLoading,
    isFetching,
    data: data,
    meta: foldersData?.meta,
    folderError,
    refetchFolder,
    handleAddFolder,
    handleCloseFolder,
    handlePinnedFolder,
    handleUpdateFolder,
    setFilters,
    setSearchField,
    setKeyword,
    setSortBy,
    setFields,
    setPage,
    setLimit,
    filters,
    searchField,
    keyword,
    sortBy,
    fields,
    page,
    limit,
    handleSort,
    sortDirection,
    handleBookmarkFolder,
  };
};
