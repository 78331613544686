import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addFile,
  getFilesByFolder,
  markAsViewed,
  deleteFile,
  pinnedFile,
  updateFile,
  resetViewers,
} from '../api/api';
import { message } from 'antd';
import { useState } from 'react';

const useSessionFile = (folderId) => {
  const [filters, setFilters] = useState({});
  const [searchField, setSearchField] = useState('');
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('-updatedAt');

  const [sortDirection, setSortDirection] = useState('desc');
  const [fields, setFields] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  // Construct query parameters
  const params = {
    ...(Object.keys(filters).length && { filters: JSON.stringify(filters) }),
    ...(searchField && keyword && { searchField, keyword }),
    ...(sortBy && typeof sortBy === 'string' && { sortBy }), // Validate sortBy is a string
    ...(fields && { fields }),
    page,
    limit,
  };

  const queryKey = ['files', folderId, params];

  const {
    isLoading: isFileLoading,
    data,
    error: fileError,
    refetch: refetchFile,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => await getFilesByFolder(folderId, params),
    enabled: !!folderId,
  });

  const queryClient = useQueryClient();

  const queryKeyMY = ['my-folders'];
  const queryKeyFIND = ['find-folders'];
  const queryKeyOPEN = ['my-open-folders'];
  const invalidateFilesQuery = () => {
    queryClient.invalidateQueries(queryKeyMY);
    queryClient.invalidateQueries(queryKeyFIND);
    queryClient.invalidateQueries(queryKeyOPEN);
  };

  const { mutate: handleAddFile } = useMutation({
    mutationFn: async (fileData) => await addFile(fileData),
    onSuccess: () => {
      message.success('File added successfully');
      refetchFile();
      invalidateFilesQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to add file');
    },
  });

  const { mutate: handlePinnedFile } = useMutation({
    mutationFn: async (id) => await pinnedFile(id),
    onSuccess: (data) => {
      message.success(`File ${data?.pinned ? 'pinned' : 'unpinned'} successfully`);
      refetchFile();
      invalidateFilesQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to pinned file');
    },
  });

  const { mutate: handleMarkAsViewed } = useMutation({
    mutationFn: async (id) => await markAsViewed(id),
    onSuccess: () => {
      message.success('File marked as viewed successfully');
      refetchFile();
      invalidateFilesQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to mark file as viewed');
    },
  });

  const { mutate: handleDeleteFile } = useMutation({
    mutationFn: async (id) => await deleteFile(id),
    onSuccess: () => {
      message.success('File deleted successfully');
      refetchFile();
      invalidateFilesQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to delete file');
    },
  });

  const { mutate: handleUpdateFile } = useMutation({
    mutationFn: async (fileData) => await updateFile(fileData),
    onSuccess: () => {
      message.success('File updated successfully');
      refetchFile();
      invalidateFilesQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to update file');
    },
  });

  const { mutate: handleResetViewers } = useMutation({
    mutationFn: async (id) => await resetViewers(id),
    onSuccess: () => {
      message.success('File viewers has been reset successfully');
      refetchFile();
      invalidateFilesQuery();
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to reset viewers');
    },
  });

  const handleSort = (column) => {
    setSortDirection((prevDirection) => {
      const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
      const sortQuery = newDirection === 'desc' ? `-${column}` : column;

      // Update the sortBy value
      setSortBy(sortQuery);

      return newDirection; // Return the new direction
    });
  };

  return {
    isFileLoading,
    data: data?.data,
    meta: data?.meta,
    fileError,
    refetchFile,
    handleAddFile,
    handleMarkAsViewed,
    handleDeleteFile,
    handlePinnedFile,
    handleUpdateFile,
    handleResetViewers,
    setFilters,
    setSearchField,
    setKeyword,
    setSortBy,
    setFields,
    setPage,
    setLimit,
    filters,
    searchField,
    keyword,
    sortBy,
    fields,
    page,
    limit,
    handleSort,
    sortDirection,
  };
};

export default useSessionFile;
