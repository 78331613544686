import { useQuery } from '@tanstack/react-query';
import { getFilesByChild } from '../api/api';

const useMySessionFiles = () => {
  const {
    isLoading: isFilesLoading,
    data: filesData,
    error: filesError,
    refetch: refetchFiles,
  } = useQuery({
    queryKey: ['files-child'],
    queryFn: async () => await getFilesByChild(),
  });

  return {
    isFilesLoading,
    filesData: filesData ?? [],
    filesError,
    refetchFiles,
  };
};

export default useMySessionFiles;
