import React, { useState } from 'react';
import styles from './VideoSeries.module.sass';
import greenTick from './../../../asset/videoSeries/GreenTick.webp';
import RedCross from './../../../asset/videoSeries/RedCross.webp';
import Vimeo from '@u-wave/react-vimeo';
import { Modal } from 'reactstrap';
import { changeWatchedToggle } from '../../../api/api.js';
import PinRed from './pin_red.png';
import PinGrey from './pin_grey.png';
import { ModalTrainingSession } from '../../ProfilePage/ModalWindowPopap/ModalTrainingSession.jsx';

let autoUnaccountedTime = 0;
let unaccountedTime = 0;
let overtime = 0;
let resultWatchingTime = 0;

let currentWatchingTime = {
  seconds: 0,
  percent: 0,
  duration: 0,
};

const VideoSeriesRow = (props) => {
  const [modal, setModal] = useState(false);
  const [isFirstRequestPerWatch, setIsFirstRequestPerWatch] = useState(true);
  const toggle = () => setModal(!modal);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const checkCalculationError = () => {
    unaccountedTime = unaccountedTime + autoUnaccountedTime;
    autoUnaccountedTime = 0;
  };

  const getDuration = (player) => {
    player.getDuration().then((duration) => {
      currentWatchingTime.videoDuration = duration;
    });

    player.on('timeupdate', (timeupdate) => {
      /** when the user scroll ahead we don't take it into account */
      if (timeupdate.percent - currentWatchingTime.percent > 0.01) {
        autoUnaccountedTime =
          autoUnaccountedTime + timeupdate.seconds - currentWatchingTime.seconds;

        setTimeout(() => {
          autoUnaccountedTime = 0;
        }, 2000);
      }

      /** when the user scroll back we take it into account */
      if (currentWatchingTime.percent - timeupdate.percent > 0.01) {
        overtime = overtime + currentWatchingTime.seconds - timeupdate.seconds;
      }
      currentWatchingTime.seconds = timeupdate.seconds;
      currentWatchingTime.percent = timeupdate.percent;
    });
  };

  const changePlay = () => {
    resultWatchingTime =
      Math.round((overtime + currentWatchingTime.seconds - unaccountedTime) * 1000) / 1000;
    if (Number.isNaN(resultWatchingTime) || resultWatchingTime < 0) {
      resultWatchingTime = currentWatchingTime.videoDuration;
    }
    if (resultWatchingTime && isFirstRequestPerWatch) {
      changeWatchedToggle(props.link, resultWatchingTime).then((response) =>
        tickCrossHandler(response),
      );
      setIsFirstRequestPerWatch(false);
    }
  };

  const tickCrossHandler = (response) => {
    if (response.status === 200) {
      props.setViewed(props.categoryName, props.count);
      /** We reset time counters after successful server request */
      currentWatchingTime = {
        seconds: 0,
        percent: 0,
        duration: 0,
      };
      autoUnaccountedTime = 0;
      unaccountedTime = 0;
      overtime = 0;
      resultWatchingTime = 0;
    }
    setIsFirstRequestPerWatch(true);
  };

  return (
    <div className={styles.tbody__row} onClick={() => console.log({ props })}>
      <Modal isOpen={modal} toggle={toggle}>
        <Vimeo
          onReady={getDuration}
          onSeeked={checkCalculationError}
          onEnd={changePlay}
          className={styles.modal_vimeo}
          autopause={true}
          responsive={true}
          video={props.link}
        ></Vimeo>
      </Modal>

      <div className={styles.videoNumber}>
        <img className={styles.circle} src={props.viewed ? greenTick : RedCross} alt="circle" />
        <span>{!!props.text && props.text}</span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <img
          className={styles.pin}
          src={props.pinned ? PinRed : PinGrey}
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedVideo(props)}
        />
        <button className={styles.button} onClick={toggle} style={{ outline: 'none' }}>
          Start
        </button>
      </div>
      {!!selectedVideo && (
        <ModalTrainingSession
          onClose={() => {
            setSelectedVideo(null);
            props.refetchFiles();
          }}
          data={selectedVideo}
        />
      )}
    </div>
  );
};

export default VideoSeriesRow;
