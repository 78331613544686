import { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getOpenFolders } from '../api/api';

const useFindSessionFolder = () => {
  const [filters, setFilters] = useState({});
  const [searchField, setSearchField] = useState('');
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('-updatedAt');

  const [sortDirection, setSortDirection] = useState('desc');
  const [fields, setFields] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // Construct query parameters
  const params = {
    ...(Object.keys(filters).length && { filters: JSON.stringify(filters) }),
    ...(searchField && keyword && { searchField, keyword }),
    ...(sortBy && typeof sortBy === 'string' && { sortBy }), // Validate sortBy is a string
    ...(fields && { fields }),
    page,
    limit,
  };

  const queryKey = ['find-folders', params]; // Unique query key for caching

  const {
    data: foldersData,
    isLoading: foldersLoading,
    isError: foldersError,
    status: foldersStatus,
    isFetching: foldersFetching,
  } = useQuery(
    queryKey,
    async () => await getOpenFolders(params), // Use your custom function
    {
      enabled: !!queryKey,
    },
  );

  const queryClient = useQueryClient();

  const invalidateFoldersQuery = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const handleSort = (column) => {
    setSortDirection((prevDirection) => {
      const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
      const sortQuery = newDirection === 'desc' ? `-${column}` : column;

      // Update the sortBy value
      setSortBy(sortQuery);

      return newDirection; // Return the new direction
    });
  };

  const data = useMemo(() => foldersData?.data || [], [foldersData]);

  return {
    data: data,
    meta: foldersData?.meta,
    foldersLoading,
    foldersFetching,
    foldersStatus,
    foldersError,
    invalidateFoldersQuery,
    setFilters,
    setSearchField,
    setKeyword,
    setSortBy,
    setFields,
    setPage,
    setLimit,
    filters,
    searchField,
    keyword,
    sortBy,
    fields,
    page,
    limit,
    handleSort,
    sortDirection,
  };
};

export default useFindSessionFolder;
