import React, { useState } from 'react';
import styles from './index.module.sass';
import { useSelector } from 'react-redux';
// import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import greenTick from './../../../asset/videoSeries/GreenTick.webp';
import RedCross from './../../../asset/videoSeries/RedCross.webp';
import { Empty, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import PinRed from '../List/pin_red.png';
import PinGrey from '../List/pin_grey.png';
import { CreateSession } from '../Create';

import Vimeo from '@u-wave/react-vimeo';
import { Modal as ModalStrap } from 'reactstrap';
import { changeWatchedToggle } from '../../../api/api';
import useMySessionFiles from '../../../hooks/useMySessionFiles';
import { ModalTrainingSession } from '../../ProfilePage/ModalWindowPopap/ModalTrainingSession';
import { DeleteSession } from '../Delete';

let autoUnaccountedTime = 0;
let unaccountedTime = 0;
let overtime = 0;
let resultWatchingTime = 0;

let currentWatchingTime = {
  seconds: 0,
  percent: 0,
  duration: 0,
};

const FileList = ({ files, handleDeleteFile, handleUpdateFile, handleMarkAsViewed }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { playerData } = useSelector((state) => state.playerDataReducer);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isFirstRequestPerWatch, setIsFirstRequestPerWatch] = useState(true);
  const [selectedPinFile, setSelectedPinFile] = useState(null);
  const { filesData, refetchFiles } = useMySessionFiles();
  const [deleted, setDeleted] = useState(null);

  const checkCalculationError = () => {
    unaccountedTime = unaccountedTime + autoUnaccountedTime;
    autoUnaccountedTime = 0;
  };

  const getDuration = (player) => {
    player.getDuration().then((duration) => {
      currentWatchingTime.videoDuration = duration;
    });

    player.on('timeupdate', (timeupdate) => {
      /** when the user scroll ahead we don't take it into account */
      if (timeupdate.percent - currentWatchingTime.percent > 0.01) {
        autoUnaccountedTime =
          autoUnaccountedTime + timeupdate.seconds - currentWatchingTime.seconds;

        setTimeout(() => {
          autoUnaccountedTime = 0;
        }, 2000);
      }

      /** when the user scroll back we take it into account */
      if (currentWatchingTime.percent - timeupdate.percent > 0.01) {
        overtime = overtime + currentWatchingTime.seconds - timeupdate.seconds;
      }
      currentWatchingTime.seconds = timeupdate.seconds;
      currentWatchingTime.percent = timeupdate.percent;
    });
  };

  const changePlay = () => {
    resultWatchingTime =
      Math.round((overtime + currentWatchingTime.seconds - unaccountedTime) * 1000) / 1000;
    if (Number.isNaN(resultWatchingTime) || resultWatchingTime < 0) {
      resultWatchingTime = currentWatchingTime.videoDuration;
    }
    if (resultWatchingTime && isFirstRequestPerWatch) {
      changeWatchedToggle(selectedVideo?.link, resultWatchingTime).then((response) =>
        tickCrossHandler(response),
      );
      setIsFirstRequestPerWatch(false);
    }
  };

  const tickCrossHandler = async (response) => {
    if (response.status === 200) {
      /** We reset time counters after successful server request */
      await handleMarkAsViewed(selectedVideo.id);
      currentWatchingTime = {
        seconds: 0,
        percent: 0,
        duration: 0,
      };
      autoUnaccountedTime = 0;
      unaccountedTime = 0;
      overtime = 0;
      resultWatchingTime = 0;
    }
    setIsFirstRequestPerWatch(true);
  };

  const showDeleteConfirm = (id) => {
    handleDeleteFile(id, {
      onSettled: () => setDeleted(null),
    });
  };

  if (files.length === 0) {
    return <Empty description="No Training Session" />;
  }

  return (
    <section className={styles.section}>
      {files.map((file) => {
        const isPlayerData = file.childId === playerData.id;
        const isViewed = file?.viewers?.includes(playerData.id);
        return (
          <div className={styles.tbody__row} key={file.id}>
            {/* <div className={styles.listItem}>
                
                <Link
                  to={{
                    pathname: `my-training-sessions/${file.id}`,
                  }}
                  className={styles.textListItem}
                >
                  {file.title}
                </Link>
              </div> */}
            <div className={styles.videoNumber}>
              <img className={styles.circle} src={isViewed ? greenTick : RedCross} alt="circle" />
              <span>{!!file.title && file.title}</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {/* {!categoriesRegex.test(selectedSubCategory) && (
                          <div onClick={() => handleHidefileClick(child)} style={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            {checkHiddenfile(child) ? (
                              <EyeFilled style={{
                                fontSize: '20px',
                                color: '#e1343f'
                              }} />
                            ) : (
                              <EyeInvisibleFilled style={{
                                fontSize: '20px',
                                color: '#86adc5'
                              }} />
                            )}
                          </div>
                      )} */}
              {/* <img
                          className={styles.bookmark}
                          src={checkBookmark(child.subTitle) ? BookmarkGreen : BookmarkGrey}
                          data-subtitle={child.subTitle}
                          onClick={(e) => switchBookmark(e)}
                        /> */}
              <>
                {/* <BorderedButton
                      title="Edit Name"
                      className={styles.button}
                      handleButtonClick={() => setSelectedFile(file)}
                    ></BorderedButton> */}
                {isPlayerData && (
                  <DeleteOutlined
                    style={{
                      border: '1px solid #000',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                    onClick={() => setDeleted(file)}
                  />
                )}
                <img
                  className={styles.pin}
                  src={filesData.some((f) => f.link === file.link) ? PinRed : PinGrey}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedPinFile(file)}
                />
                <button
                  className={`${styles.button} ${styles.button_play}`}
                  onClick={() => setSelectedVideo(file)}
                  style={{ outline: 'none' }}
                >
                  Start
                </button>
              </>
            </div>
          </div>
        );
      })}
      {!!selectedFile && (
        <CreateSession
          edit
          folder={selectedFile}
          handleSubmit={handleUpdateFile}
          onClose={() => setSelectedFile(null)}
        />
      )}
      <ModalStrap isOpen={!!selectedVideo} toggle={() => setSelectedVideo(null)}>
        <Vimeo
          onReady={getDuration}
          onSeeked={checkCalculationError}
          onEnd={changePlay}
          className={styles.modal_vimeo}
          autopause={true}
          responsive={true}
          video={selectedVideo?.link}
        />
      </ModalStrap>
      {!!selectedPinFile && (
        <ModalTrainingSession
          data={selectedPinFile}
          onClose={() => {
            setSelectedPinFile(null);
            refetchFiles();
          }}
        />
      )}
      {!!deleted && (
        <DeleteSession
          title="Delete Video in Training Session?"
          message="Are you sure you want to delete this video?"
          data={deleted}
          handleSubmit={showDeleteConfirm}
          onClose={() => setDeleted(null)}
        />
      )}
    </section>
  );
};

export default FileList;
