import { SubfolderPageUrl } from '../constants/const';
import { ActionType } from './dashboardChildReducer';
import path from '../config/routes.json';

/**
 * title: title from the layout
 * vimeoName: vimeo title - we use it if we construct the video categories url
 * in MainDashboardCard
 * we need save this name for the following using in the videoCategories component
 * subTitle: '',
 * actionType: this field contains action which we transfer to the dispatcher
 * img: '/img/dashboardIcons/warm-up.png',
 * link: SubfolderPageUrl.warmUp,
 */

const initialState = {
  data: {
    mainCategories: [
      {
        title: 'Free Section',
        subTitle: '',
        img: '/img/dashboardIcons/football-field.webp',
        link: SubfolderPageUrl.freeSection,
      },

      {
        title: 'My Teams',
        img: '/img/dashboardIcons/my-teams.webp',
        link: path.dashboardChild.teams,
      },
    ],
    quickLinks: [
      {
        title: 'Juggling & Warm-Up',
        vimeoName: 'Juggling and Warm-Up',
        subTitle: '',
        actionType: ActionType.SET_WARM_UP_DATA,
        img: '/img/dashboardIcons/Juggling_And_Aerial_Control.webp',
        link: SubfolderPageUrl.warmUp,
      },
      {
        title: 'Ball Mastery',
        vimeoName: 'Ball Mastery',
        subTitle: '',
        actionType: ActionType.SET_BALL_MASTERY_DATA,
        img: '/img/dashboardIcons/Ball_Mastery.webp',
        link: SubfolderPageUrl.ballMastery,
      },
      {
        title: 'Dribbling & Turns',
        vimeoName: 'Dribbling Games',
        subTitle: '',
        actionType: ActionType.SET_DRIBBLING_GAMES_DATA,
        img: '/img/dashboardIcons/Dribbling_And_Turns.webp',
        link: SubfolderPageUrl.dribblingGames,
      },
      {
        title: 'Wall & Passing',
        vimeoName: 'Passing Receiving',
        subTitle: '',
        actionType: ActionType.SET_PASSING_RECEIVING_DATA,
        img: '/img/dashboardIcons/Wall_And_Passing.webp',
        link: SubfolderPageUrl.passingReceivingData,
      },
      {
        title: '1V1 & Finishing',
        vimeoName: '1V1 Finishing',
        subTitle: '',
        actionType: ActionType.SET_1V1_FINISHING_DATA,
        img: '/img/dashboardIcons/1v1_And_Finishing.webp',
        link: SubfolderPageUrl.finishing1v1,
      },
      {
        title: 'Fitness & Agility',
        vimeoName: 'Fitness Agility',
        subTitle: '',
        actionType: ActionType.SET_FITNESS_AGILITY_DATA,
        img: '/img/dashboardIcons/Fitness_And_Agility.webp',
        link: SubfolderPageUrl.allTrainingAreasSubfolder,
      },
    ],
    premiumAreas: [
      {
        title: 'All Programs',
        subTitle: '',
        actionType: '',
        isSkillAreas: true,
        img: '/img/dashboardIcons/5000-videos.png',
        link: SubfolderPageUrl.allPrograms,
      },
      {
        title: `Get Started`,
        img: '/img/dashboardIcons/Ball-Mastery-Mix.webp',
        // link: SubfolderPageUrl.videoSeries + '/(Intermed to Adv) Ball Mastery/true',
        link: SubfolderPageUrl.getStarted,
      },
      {
        title: 'My Favorites',
        img: '/img/dashboardIcons/my-favorites.webp',
        link: SubfolderPageUrl.playerJourneys,
      },
      {
        title: 'My Training Sessions',
        subTitle: '',
        img: '/img/dashboardIcons/training-sessions.png',
        link: '/my-training-sessions',
      },
      {
        title: 'My Homework',
        img: '/img/dashboardIcons/board.webp',
        link: SubfolderPageUrl.teamHomework,
      },
      // {
      //   title: '30 Day Plan',
      //   img: '/img/dashboardIcons/30-day-plans.webp',
      //   link: SubfolderPageUrl['30DayPlan'],
      // },
      {
        title: 'My Training Goal',
        subTitle: '',
        img: '/img/dashboardIcons/AST_My_Training_Goal_icon.webp',
        link: '/trainingGoal',
      },
    ],
    specialPrograms: [
      // {
      //   title: 'Freestyle',
      //   vimeoName: 'Brief Freestyle',
      //   subTitle: '',
      //   img: '/img/dashboardIcons/freestyle.webp',
      //   link: SubfolderPageUrl.videoCategories,
      // },
      {
        title: '1v1 & Games',
        vimeoName: 'Soccer Chasing',
        subTitle: '',
        actionType: ActionType.SET_1V1_GAMES_DATA,
        img: '/img/dashboardIcons/skills-library.webp',
        link: SubfolderPageUrl.allTrainingAreasSubfolder,
      },
      // {
      //   title: 'Skill Challenges',
      //   vimeoName: 'Anytime Skills',
      //   subTitle: '',
      //   img: '/img/dashboardIcons/1v1-games.webp',
      //   link: SubfolderPageUrl.videoCategories,
      // },
      // {
      //   title: 'Skills Library',
      //   vimeoName: 'Brief Freestyle',
      //   subTitle: '',
      //   img: '/img/dashboardIcons/skills-challenges.webp',
      //   link: '/dashboardChild/videoSeries/anytimeSkills',
      // },
      {
        title: 'Video Search',
        subTitle: '',
        img: '/img/dashboardIcons/video-search.webp',
        link: '/search',
      },
      // {
      //   title: 'My Training Goal',
      //   subTitle: '',
      //   img: '/img/dashboardIcons/training-plan.webp',
      //   link: '/trainingGoal',
      // },
    ],
  },
};

const mainFoldersDashboardChildReducer = (state = initialState) => state;

export default mainFoldersDashboardChildReducer;
