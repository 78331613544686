import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const ASTPagination = ({ maxPage, currentPage, setCurrentPage, limit }) => {
  const handleChangePage = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setCurrentPage(newPage);
    }
  };

  const getArrowStyle = (disabled) => ({
    opacity: disabled ? '0.4' : '1',
    fontSize: '13px',
    padding: '5px 10px',
  });

  const generatePaginationItems = () => {
    const pages = [];

    const addPage = (pageNumber, isActive = false, isDisabled = false) => {
      pages.push(
        <PaginationItem key={pageNumber} active={isActive} disabled={isDisabled}>
          <PaginationLink
            onClick={() => !isDisabled && handleChangePage(pageNumber)}
            style={getArrowStyle(isDisabled)}
          >
            {pageNumber}
          </PaginationLink>
        </PaginationItem>,
      );
    };

    const addEllipsis = (key) => {
      pages.push(
        <PaginationItem key={key} disabled>
          <PaginationLink style={getArrowStyle(true)}>...</PaginationLink>
        </PaginationItem>,
      );
    };

    if (maxPage <= limit) {
      for (let i = 1; i <= maxPage; i++) {
        addPage(i, currentPage === i);
      }
    } else {
      addPage(1, currentPage === 1);

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(maxPage - 1, currentPage + 1);

      if (currentPage > 3) {
        addEllipsis('startEllipsis');
      }

      for (let i = startPage; i <= endPage; i++) {
        addPage(i, currentPage === i);
      }

      if (currentPage < maxPage - 2) {
        addEllipsis('endEllipsis');
      }

      addPage(maxPage, currentPage === maxPage);
    }

    return pages;
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
      <Pagination>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            first
            onClick={() => handleChangePage(1)}
            style={getArrowStyle(currentPage === 1)}
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handleChangePage(currentPage - 1)}
            style={getArrowStyle(currentPage === 1)}
          />
        </PaginationItem>
        {generatePaginationItems()}
        <PaginationItem disabled={currentPage === maxPage}>
          <PaginationLink
            next
            onClick={() => handleChangePage(currentPage + 1)}
            style={getArrowStyle(currentPage === maxPage)}
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage === maxPage}>
          <PaginationLink
            last
            onClick={() => handleChangePage(maxPage)}
            style={getArrowStyle(currentPage === maxPage)}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default ASTPagination;
